
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Chip from '@mui/material/Chip';

import ThunksBooking from "../../../../redux/actions/thunks/bookings";
import ThunkPayment from "../../../../redux/actions/thunks/paymant";
import AppSettings from "../../AppSettings";
import Input from "../../../../components/commons/Input";
import AddBooking from "../AddBooking";
import { cartypes } from "../../Bookings/VehicleTypes";
import { DifCars } from "../../../../enum";
import vehicles from "../../../../assets/VehicleSvgs";
import TrackingEventDialogue from "./TrackingEventsDialogue";
import { showGoogleMap , generateReceiptNumber } from "../../../../helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
  },
  ul: {
    backgroundColor: "inherit",
  },
  root_Tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  //accordian
  root_accordian: {
    width: "100%",
    backgroundColor: "red",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  counts: {
    border: '1px solid black',
    padding: 10
  },
  rowStyle: {
    display: "flex",
    direction: "row",
    borderStyle: "solid",
    borderWidth: '0px 0px 1px 0px ',
    borderColor: "#ced4da",
    marginBottom: 10
  },
  textfield_upper: {
    marginTop: '-8px',
    marginLeft: 10,
    marginBottom: 5,
    width: '53%',
    height: 0,
  },
  textfield_upperFare: {
    marginTop: '-8px',
    marginLeft: 10,
    marginBottom: 5,
    width: '58%',
    height: 0,
  },
  textfield_total: {
    marginTop: '-8px',
    marginLeft: 10,
    marginBottom: 5,
    width: '57%',
    height: 0,
  },
}));

function SinglePayInDetails({
  selectedRow,
  selectedWeek,
  clientId,
  user,
  SaveAdditionalPayin,
  UpdateAdditionalPayin,
  UpdateBookingOrg,
  UpdateAdditionalBookingOrg,
  setPaymentSelected,
  loadData,
  DeleteAdditionalBookingOrg,
  GetDriverEventsBybooking,
  GetPaymentByYear,
  allData,
  setLoader,
  GetSinglePayin,
  CreatePayinPayment
}) {
  const classes = useStyles();
  const [bookings, setBookings] = useState(null);
  const [from, setfrom] = useState(null);
  const [to, setTo] = useState(null);
  const [editData, setEditData] = useState(null);
  const [editDataTotal, setEditDataTotal] = useState(null);
  const [additional, setAdditional] = useState([]);
  const [total, setTotal] = useState(null);
  const [driver, setDriver] = useState(null);
  const [events, setEvents] = useState([]);
  const [dialogueOpen, setDialogueOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleEventDetail = async (bookingData, e) => {
    e.stopPropagation()
    const bookingEvent = await GetDriverEventsBybooking(bookingData.id);
    setEvents(bookingEvent)
  }
  useEffect(() => {
    events.length > 0 && setDialogueOpen(true)
  }, [events])

  const initalLoad = async () => {
    setDriver(selectedRow)
    setClient(selectedRow)
    let data = await GetSinglePayin(user.id, selectedWeek, selectedRow.client.id);
    
    
    data?.bookings.map(booking => {
      if (booking?.payinAdditionals && booking?.payinAdditionals.length > 0) {
        booking.additionalTotal = booking?.payinAdditionals?.reduce((a, b) => a + parseFloat(b.amount), 0);
      } else {
        booking.additionalTotal = 0;
      }
    })

    let total_ = data?.bookings?.reduce((a, b) => a + (parseFloat(b.additionalTotal) || 0), 0)

    setTotal((data?.bookings?.reduce((a, b) => a + ( b.fare || 0), 0)) + total_)
    setBookings(data?.bookings)
    setfrom(data?.from)
    setTo(data?.to)
  }

  const updateSelectedRow = (new_id) => {
    selectedRow.userAdditionaBookingIds.push(new_id)
    selectedRow.totalRide++;
  }

  const handleClose = async () => {
    setEditData(null)
    await initalLoad();
    setOpen(false);
  }

  useEffect(() => {
    (async () => {
      await initalLoad();
    })();
  }, [])

  const updateAdditionals = (e, index) => {
    const newInputs = [...additional];
    if (e.target.name == "amount") newInputs[index].amount = parseFloat(e.target.value || 0);
    if (e.target.name == "reason") newInputs[index].reason = e.target.value;
    setAdditional(newInputs);

    if (e.target.name == "amount") {
      setEditDataTotal((parseFloat((editData?.fare || 0)) + newInputs.reduce((a, b) => a + (b.amount == '' ? 0 : parseFloat(b.amount)), 0)).toFixed(2))
    }
  }

  return (
    <>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button style={{ marginLeft: 10, marginBottom: 30, color: "#2AABD5", }} onClick={async () => {
          setPaymentSelected(false)
          await loadData(true)
        }}>

          <ArrowBackIcon sx={{
            color: "#2AABD5",
            borderRadius: "0%",
          }} />
          Go back to PayIns
        </Button>
        <h2>PAYINS</h2>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            
          </div>
          <div>
            {driver !== null &&
              <AddBooking from={from} to={to} driver={driver?.driver?.id} clientId = {clientId} loadData={loadData} initalLoad={initalLoad} updateSelectedRow={updateSelectedRow} />
            }
            <Button
              style={{
                borderRadius: 8,
                marginTop: 10,
                backgroundColor: "#2AABD5",
                color: "#FFFFFF",
                height: 40,
                textTransform: "none",
                float: "right"
              }}
              variant="contained"
              onClick={async () => {
                if (window.confirm('Are you sure you want to approve this payment?')) {
                  setLoader(true)
                  
                  // setLoader(true)
                  // create func for creating invoice number
                  let receiptNo = await generateReceiptNumber(user, GetPaymentByYear);
                  
                  const bulkObj = {
                    ids: selectedRow.userBookingIds,
                    obj: {
                      receiptNo
                    },
                  }

                  const bulkAdditionalObj = {
                    ids: selectedRow.userAdditionaBookingIds,
                    obj: {
                      receiptNo
                    },
                  }
                  let obj = {
                    isPaid: false,
                    amount: total,
                    weekStart: from,
                    weekEnd: to,
                    receiptNo,
                    totalRide: selectedRow.totalRide,
                    userId: selectedRow.client.id,
                    orgId: user.id,
                    bulkAdditionalObj: bulkAdditionalObj,
                    bulkObj: bulkObj //normal booking
                  }

                  await CreatePayinPayment(obj);

                  const findedIndex = allData.findIndex(item => item.client.id == selectedRow.client.id)
                  const page = findedIndex / 5;
                  const pageToGo = page.toString().split('.')[0];
                  await loadData(Number(pageToGo) + 1)
                  initalLoad()
                  setPaymentSelected(false);
                  setLoader(false)
                };

              }}
            >
              Approved
            </Button>
          </div>
        </div>
      </Grid>

      <div style={{ margin: 5 }}> <span style={{ fontWeight: "bold", marginRight: 5 }}> Start Date: </span> {from} </div>
      <div style={{ margin: 5 }}> <span style={{ fontWeight: "bold", marginRight: 12 }}> End Date: </span> {to}</div>
      <div style={{ margin: 5 }}> <span style={{ fontWeight: "bold", marginRight: 5 }}> Display Name:</span> {selectedRow?.client?.firstName} {selectedRow?.client?.lastName} </div>
      <div style={{ margin: 5 }}> <span style={{ fontWeight: "bold", marginRight: 5 }}> Company Name:</span> {selectedRow?.client?.companyName}</div>


      <div style={{ display: "flex", direction: "row", borderStyle: "solid", borderWidth: '0px 0px 1px 0px ', borderColor: "#ced4da" }} >
        <div style={{ width: '2%', margin: 5 }}><h4> # </h4></div>
        <div style={{ width: '8%', margin: 5 }}><h4> Date </h4></div>
        <div style={{ width: '12%', margin: 5, paddingLeft: 6 }}><h4> Pickup </h4></div>
        <div style={{ width: '12%', margin: 5 }}><h4> Destination </h4></div>
        <div style={{ width: '10%', margin: 5, paddingLeft: 16 }}><h4> Guest Name </h4></div>
        <div style={{ width: '10%', margin: 5, paddingLeft: 22  }}><h4> Booking ID </h4></div>
        <div style={{ width: '8%', margin: 5 }}><h4> Service Level </h4></div>
        <div style={{ width: '17%', margin: 5, paddingLeft: 48 }}><h4> Status </h4></div>
        <div style={{ width: '10%', margin: 5 }}><h4> Rate </h4></div>
        <div style={{ width: '8%', margin: 5 }}><h4> Action </h4></div>
      </div>


      {bookings?.map((booking, index) =>
        <>
          <div style={{ display: "flex", direction: "row", borderStyle: "solid", borderWidth: '0px 0px 1px 0px', borderColor: "#ced4da" }} onClick={() => {

            setEditData(booking)
            if (booking.payinAdditionals) {
              setAdditional(booking.payinAdditionals)
            }
            setEditDataTotal((parseFloat(booking?.fare) || 0) + booking.payinAdditionals.reduce((a, b) => a + (b.amount == '' ? 0 : parseFloat(b.amount)), 0))
            handleOpen()
          }}>
            <div style={{ width: '2%', margin: 5 }}>{index + 1}</div>
            <div style={{ width: '8%', margin: 5 }}>{moment(booking.dateTime).format("YYYY-MM-DD")}</div>
            <div style={{ width: '12%', margin: 5, cursor: "pointer" }} onClick={(e) => {
              e.stopPropagation();
              showGoogleMap(booking.pickup.coordinates, booking.destination.coordinates);
            }}>{booking.pickup.name}</div>
            <div style={{ width: '12%', margin: 5, cursor: "pointer" }} onClick={(e) => {
              e.stopPropagation();
              showGoogleMap(booking.pickup.coordinates, booking.destination.coordinates)
            }}>{booking.destination.name}</div>
            <div style={{ width: '10%', margin: 5 }}>{booking.guestName}</div>
            <div style={{ width: '10%', margin: 5 }}>{booking.bookingReference || booking.refId}</div>
            <div style={{ width: '8%', margin: 5 }}>
              {cartypes
                .filter((cars) => cars.value == booking.carType)
                .map((carname) => (
                  <div style={{ width: DifCars[booking?.carType] ? 100 : 0, marginLeft: DifCars[booking?.carType] ? -2 : 0 }}>{vehicles(booking.carType, "#4b4b4b", '50')}</div>
                ))}
            </div>
            <div style={{ width: '14%', margin: 5 }} onClick={(e) => handleEventDetail(booking, e)}>
              <Chip
                label={booking.rideEnd ?
                  booking.cancelled ? "Customer No Show" : "Completed"
                  :
                  booking.cancelled ? "Cancelled" : "Incomplete"}

                color={booking.rideEnd ?
                  booking.cancelled ? "error" : "success"
                  :
                  booking.cancelled ? "error" : "success"}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div style={{ width: '10%', margin: 5 }}>€{booking?.fare?.toFixed(2) || "0.00"}</div>

            <div style={{ width: '8%', margin: 5 }}>
              {booking.isAdditional &&
                <Button style={{ borderRadius: 25, minWidth: 40 }} size="sm" onClick={async (e) => {
                  e.stopPropagation();
                  if (window.confirm("Are you sure you want to delete the booking?")) {
                    await DeleteAdditionalBookingOrg(booking.id);
                    await initalLoad();
                  }
                }}>
                  <DeleteOutline />
                </Button>
              }
            </div>
          </div>
          {booking?.payinAdditionals?.map(Additional =>
            <>
              <div style={{ display: "flex", direction: "row", borderStyle: "solid", borderWidth: '0px 0px 1px 0px', borderColor: "#ced4da" }} >
                <div style={{ width: '190%', margin: 5 }}>{Additional.reason}</div>
                <div style={{ width: '32.5%', margin: 5 }}>€{Additional?.amount?.toFixed(2)}</div>
                <div style={{ width: '10%', margin: 5 }}></div>
              </div>
            </>
          )}
        </>
      )}
      <div style={{ display: "flex", direction: "row" }}>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '11%', paddingRight: '-1px' }}><h3 style={{ marginLeft: 12 }}>Total</h3></div>
        <div style={{ width: '10%' }}><h3>€{total?.toFixed(2)}</h3></div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          bgcolor: 'background.paper',
          borderRadius: 5,
          p: 4,
        }}>
          <Grid container>

            <div style={{ display: "flex", direction: "row" }}>
              <div style={{ padding: 10, width: "30vw" }}>
                Booking ID: <span style={{ paddingLeft: 10, }}>{editData?.bookingReference || editData?.refId}</span>
              </div>

              <div style={{ padding: 10, marginLeft: "-30px", marginBottom: 10 }}>
                Fare:
                <Input
                  value={editData?.fare}
                  type="text"
                  size="small"
                  name="fare"
                  className={classes.textfield_upperFare}
                  onChange={(e) => {
                    setEditDataTotal((parseFloat(e.target.value !== '' ? e.target.value : 0) + additional.reduce((a, b) => a + (b.amount == '' ? 0 : parseFloat(b.amount)), 0)).toFixed(2))
                    setEditData({
                      ...editData,
                      fare: e.target.value
                    })

                  }}
                />
              </div>
            </div>
            <div id="wrapper" style={{ overflow: "hidden", width: "100vw" }}>
              {additional.map((item, index) =>
                <div id="c1" style={{ float: "left" }}>
                  <div style={{ display: "flex", direction: "row" }} key={index}>
                    <div style={{ padding: 10, marginLeft: 26, marginBottom: 10 }}>
                      Reason:
                      <Input
                        value={item?.reason}
                        type="text"
                        size="small"
                        name="reason"
                        className={classes.textfield_upper}
                        onChange={(e) => updateAdditionals(e, index)}
                      />
                    </div>
                    <div style={{ padding: 10, marginLeft: 17, marginBottom: 10 }}>
                      <label>Amount:</label>
                      <Input
                        value={item?.amount}
                        type="text"
                        size="small"
                        name="amount"
                        className={classes.textfield_upper}
                        onChange={(e) => updateAdditionals(e, index)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div id="c2" style={{ float: "right" }}>
                <Button
                  style={{
                    padding: 0,
                    borderRadius: 8,
                    backgroundColor: "#2AABD5",
                    color: "#FFFFFF",
                    height: 40,
                  }}
                  onClick={() => {
                    if (editData.isAdditional) {
                      setAdditional(prev => [...prev, { reason: '', amount: 0, additionalBooking: editData.id }])
                    } else {
                      setAdditional(prev => [...prev, { reason: '', amount: 0, bookingId: editData.id }])
                    }
                  }}
                  variant="contained"
                >
                  +
                </Button>

              </div>
            </div>

            <div style={{ overflow: "hidden", width: "100vw" }}>
              <div style={{ float: "left" }}></div>
              <div style={{ float: "right" }}>
                <div style={{ padding: 10, marginRight: 89, marginBottom: 20 }}>
                  <label>Total:</label>
                  <Input
                    value={editDataTotal}
                    type="text"
                    size="small"
                    name="name"
                    className={classes.textfield_total}
                  />
                </div>
              </div>
            </div>

            <Button
              style={{
                margin: "0 auto",
                borderRadius: 8,
                backgroundColor: "#2AABD5",
                color: "#FFFFFF",
                height: 40,
              }}
              onClick={async () => {
               
                try {
                  let isEmpty = additional.filter(item => (item.reason == ""))
                  if(isEmpty.length > 0) { alert("Reason can not be empty!")}
                  else {
                    let existed = additional.filter(item => item?.id)
                    await UpdateAdditionalPayin(existed);

                    let notExists = additional.filter(item => !item?.id)
                    await SaveAdditionalPayin(notExists);
                    if (editData.isAdditional) {
                      await UpdateAdditionalBookingOrg(editData.id, { fare: editData.fare })
                    } else {
                      await UpdateBookingOrg(editData.id, { fare: editData.fare })
                    }
                    handleClose()
                  }
                  
                } catch (error) {
                  console.log(error)
                }
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
        </Box>
      </Modal>
      <TrackingEventDialogue dialogueOpen={dialogueOpen} setDialogueOpen={setDialogueOpen} events={events} />
    </>
  );
}

const mapState = (state) => ({
  user: state.userInfo.user,
  data: state.bookings.api.response.data,
  loading: state.bookings.api.loading,
  error: state.bookings.api.error,

});

const mapDispatch = {
  loadSettingVat: AppSettings.loadSettingVat,
  UpdateBookingOrg: ThunksBooking.UpdateBookingOrg,
  UpdateAdditionalBookingOrg: ThunksBooking.UpdateAdditionalBookingOrg,
  DeleteAdditionalBookingOrg: ThunksBooking.DeleteAdditionalBookingOrg,
  UpdateAdditionalPayin: ThunksBooking.UpdateAdditionalPayin,
  SaveAdditionalPayin: ThunksBooking.SaveAdditionalPayin,
  GetSinglePayout: ThunksBooking.GetSinglePayout,
  GetDriverEventsBybooking: ThunksBooking.GetDriverEventsBybooking,
  GetBookingdriver: ThunksBooking.GetBookingdriver,
  GetPaymentByYear: ThunkPayment.GetPaidPaymentByYear,
  CreatePayment: ThunkPayment.CreatePayment,
  GetPayouts: ThunkPayment.GetPayments,
  GetSinglePayin: ThunksBooking.GetSinglePayin,
  CreatePayinPayment: ThunkPayment.CreatePayinPayment,
}

export default connect(mapState, mapDispatch)(SinglePayInDetails);
