import MaterialTable from "material-table";
import _ from "lodash";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ThunksNetworks from "../../../redux/actions/thunks/networks";
import { useState, useEffect } from "react";

function DetailTable({ rowData, getAllForNetwork }) {

  const [all, setAll] = useState([]);

  const getAll = async (id) => {
    let result = await getAllForNetwork(id);
    setAll(result);
  };
  useEffect(()=>{
    getAll(rowData.id);
  },[rowData]);
  const columns = [
    {
      title: "Display Name",
      field: "displayName",
      render: (rowData) => {
        return rowData?.displayName ? rowData.displayName : rowData.fullName
      }
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "role",
    }
  ];

  return (
    <MaterialTable
      options={{ search: true, actionsColumnIndex: -1 }}
      title="Drivers and Dispatchers"
      icons={tableIcons}
      columns={columns}
      data={all}   
    />
  );
}


const mapDispatch = {
  getAllForNetwork: ThunksNetworks.getAllForNetwork

};

export default connect(null, mapDispatch)(DetailTable);