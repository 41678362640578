import { combineReducers, Reducer } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import signUpReducer from "./sign-up";
import vissibilitiesReducer from "./visibilities";
import sharedApiReducer from "./shared/api";
import loginReducer from "./login";
import reportReducer from "./reports";
import userReducer from "./user";
import storage from "redux-persist/lib/storage";
import { LOGIN_API } from "../actions/local/constants/login";
import SIGN_UP, { SIGN_UP_API } from "../actions/local/constants/sign-up";
import { TAXI_COMPANIES_API } from "../actions/local/constants/taxi-companies";
import { ONBOARDING_LISTING_API } from "../actions/local/constants/onboarding-listing";
import {
  ADD_BRAND_API,
  VEHICLES_LISTING_API,
} from "../actions/local/constants/vehicle-listing";
import {
  BOOKINGS_LISTING_API,
  DISPATCHERS_LISTING_API,
  CREATE_BOOKING_API,
  BOOKING_INTEGRATION_API,
  BOOKING_STATS,
} from "../actions/local/constants/bookings";
import { SHEET } from "../actions/local/constants/spread-sheet";
import { APPROVE_API } from "../actions/local/constants/onboarding-verification";
import rejectReducer from "./reject-onboarding";
import bookingReducer from "./bookings";
import { DRIVERS_API } from "../actions/local/constants/drivers";
import { PASSENGERS_API } from "../actions/local/constants/passengers";
import {
  ADD_CUSTOMER_API,
  CUSTOMERS_API,
  CUSTOMER_DISPATCHER_API,
} from "../actions/local/constants/customers";
import { PAYMENT_PAYINS_API } from "../actions/local/constants/paymentPayins";
import {
  PROFILE_API,
  PROFILE_PASSWORD_API,
} from "../actions/local/constants/user";
import orgUsers from "./OrgUsers";
import customerUsers from "./CustomerUser";
import subAdmin from "./SubAdmins";
import { NETWORKS_API } from "../actions/local/constants/networks";
import { IMPORT_SHEET_API } from "../actions/local/constants/spread-sheet";
const customerBookingReducer = sharedApiReducer({
  ...CREATE_BOOKING_API,
});

const profilePasswordApiReducer = sharedApiReducer({
  ...PROFILE_PASSWORD_API,
});

const networksReducer = sharedApiReducer({
  ...NETWORKS_API,
});

const profileApiReducer = sharedApiReducer({
  ...PROFILE_API,
});
const loginApiReducer = sharedApiReducer({
  ...LOGIN_API,
});
const signupApiReducer = sharedApiReducer({
  ...SIGN_UP_API,
});

const taxiCompaniesReducer = sharedApiReducer({
  ...TAXI_COMPANIES_API,
});

const addBrandReducer = sharedApiReducer({
  ...ADD_BRAND_API,
});

const addCustomerReducer = sharedApiReducer({
  ...ADD_CUSTOMER_API,
});
const onboardingListingReducer = sharedApiReducer({
  ...ONBOARDING_LISTING_API,
});

const vehiclesListingReducer = sharedApiReducer({
  ...VEHICLES_LISTING_API,
});

const driversReducer = sharedApiReducer({
  ...DRIVERS_API,
});

const customersReducer = sharedApiReducer({
  ...CUSTOMERS_API,
});
const customerDispatcherReducer = sharedApiReducer({
  ...CUSTOMER_DISPATCHER_API,
});

const bookingsListingReducer = sharedApiReducer({
  ...BOOKINGS_LISTING_API,
});
const dispatchersListingReducer = sharedApiReducer({
  ...DISPATCHERS_LISTING_API,
});

const approveReducer = sharedApiReducer({
  ...APPROVE_API,
});
const bookingIntegration = sharedApiReducer({
  ...BOOKING_INTEGRATION_API,
});
const passengersReducer = sharedApiReducer({
  ...PASSENGERS_API,
});
const approvedOverTotal = sharedApiReducer({
  ...BOOKING_STATS,
});
const imoprtBookingReducer = sharedApiReducer({
  ...IMPORT_SHEET_API,
});
const paymentPayinsReducer = sharedApiReducer({
  ...PAYMENT_PAYINS_API,
});
const loginWrapperReducer: Reducer<any> = combineReducers({
  api: loginApiReducer,
  state: loginReducer,
});
const signupWrapperReducer: Reducer<any> = combineReducers({
  api: signupApiReducer,
  state: signUpReducer,
});

const bookingWrapperReducer: Reducer<any> = combineReducers({
  api: bookingsListingReducer,
  state: bookingReducer,
});

const sheetReducer = sharedApiReducer({
  ...SHEET.SHEET_API,
  SET_RETRY: ""
});

const rootReducer: any = combineReducers({
  visibilities: vissibilitiesReducer,
  reports:reportReducer,
  signUp: signupWrapperReducer,
  login: loginWrapperReducer,
  userInfo: userReducer,
  taxiCompanies: taxiCompaniesReducer,
  onboardingListing: onboardingListingReducer,
  vehiclesListing: vehiclesListingReducer,
  approve: approveReducer,
  reject: rejectReducer,
  bookings: bookingWrapperReducer,
  approvedOverTotal: approvedOverTotal,
  dispatchers: dispatchersListingReducer,
  drivers: driversReducer,
  addBrand: addBrandReducer,
  customers: customersReducer,
  addCustomer: addCustomerReducer,
  profile: profileApiReducer,
  customerDispatcher: customerDispatcherReducer,
  profilePassword: profilePasswordApiReducer,
  customerbooking: customerBookingReducer,
  bookingIntegration: bookingIntegration,
  sheet: sheetReducer,
  passengers: passengersReducer,
  orgUsers: orgUsers,
  customerUsers: customerUsers,
  subAdmin: subAdmin,
  networks:networksReducer,
  imoprtBookingReducer:imoprtBookingReducer,
  paymentPayins:paymentPayinsReducer
});
const persistConfig = {
  key: "root",
  whiteList: ["user"],
  storage: storage,
  timeout: 0,
};
/* change: explicit type definition */
export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(persistConfig, rootReducer);
