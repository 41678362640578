import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./DashboardLayout";
import DriversListing from "./Onboardings";
import TaxiCompanies from "./TaxiCompanies";
import VehicleListing from "./VehicleListing";
import BookingDetails from "./Bookings";
import OrgUsers from "./OrgUsers";
import { connect } from "react-redux";
import { ReactElement } from "react";
import DispatchersListing from "./DispatchersListing";
import AppSettings from "./AppSettings";
import Drivers from "./Drivers";
import Customers from "./Customers";
import CustomerProfile from "./CustomerProfile";
import IntegrationBooking from "./IntegrationBooking";
import IntegrationDispatcher from "./IntegrationDispatcher";
import IntegrationCommission from "./IntegrationCommission";
import PaymentDetails from "./Payments";
import APIKey from "./APIKey";
import Settings from "./Settings";
import TermsAndConditions from "./AppSettings/TermsAndConditions";
import ManagePassenger from "./ManagePassenger";
import ChangePassword from "./OrgUsers/ChangePassword";
import ChangeCustomerPassword from "./CustomerUser/ChangePassword";
import CustomerUser from "./CustomerUser";
import Reports from "./Reports";
import ManageNetworks from "./Networks";
import SubAdmins from "./SubAdmins";
import SubAdminChangePass from "./SubAdmins/ChangePassword";
import CustomerInvoice from "./CustomerInvoice";
import CustomerSetting from "./CustomerSetting";
import CustomerContactUs from "./CustomerContactUs";
interface Props {
  role: any;
  user:any;
}
function Dashboard({ role, user }: Props): ReactElement {
  console.log("role check", role);

  return (
    <DashboardLayout user={user}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                role == "Admin"
                  ? "/dashboard/onboardings"
                  : role == "Organization"
                  ? "/dashboard/bookings"
                  : role == "OrgUser"
                  ? "/dashboard/change-pass"
                  : role == "CustomerUser"
                  ? "/dashboard/customer-changePass"
                  : role == "SubAdmin"
                  ? "/dashboard/sub-changePass"
                  : "/dashboard/customer-profile"
              }
            />
          }
        />
        <Route path="/bookings" element={<BookingDetails />} />
        <Route path="/org-users" element={<OrgUsers />} />
        <Route path="/change-pass" element={<ChangePassword />} />
        <Route path="/paiment-details" element={<PaymentDetails />} />
        <Route path="/payments" element={<PaymentDetails />} />
        <Route path="/onboardings" element={<DriversListing />} />
        <Route path="/taxi-companies" element={<TaxiCompanies />} />
        <Route path="/sub-admin" element={<SubAdmins />} />
        <Route path="/sub-changePass" element={<SubAdminChangePass />} />
        <Route path="/vehicles" element={<VehicleListing />} />
        <Route path="/networks" element={<ManageNetworks/>} />
        <Route path="/dispatchers" element={<DispatchersListing />} />
        <Route path="/settings" element={<AppSettings />} />
        <Route
          path="/settings/termsandconditions"
          element={<TermsAndConditions />}
        />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customer-profile" element={<CustomerProfile />} />
        <Route
          path="/customer-changePass"
          element={<ChangeCustomerPassword />}
        />
        <Route path="/customer-bookings" element={<BookingDetails />} />
        <Route path="/customer-users" element={<CustomerUser />} />
        <Route path="/integration" element={<IntegrationBooking />} />
        <Route path="/integration/api-key" element={<APIKey />} />
        <Route path="/customer-invoice" element={<CustomerInvoice />} />
        {/* <Route path="/customer-setting" element={<CustomerSetting />} /> */}
        <Route path="/customer-contact" element={<CustomerContactUs />} />
        <Route
          path="/integration/dispatchers"
          element={<IntegrationDispatcher />}
        />
        <Route
          path="/integration/commission"
          element={<IntegrationCommission />}
        />
        <Route path="/organization/settings" element={<Settings />} />
        <Route path="/organization/reports" element={<Reports/>} />

        <Route path="/manage-passengers" element={<ManagePassenger/>} />
      </Routes>
    </DashboardLayout>
  );
}

const mapState = (state: any) => ({
  role: state.userInfo.user.role,
  user: state.userInfo.user
});

export default connect(mapState)(Dashboard);
