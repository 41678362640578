import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarIcon from "@mui/icons-material/Star";
import { connect } from "react-redux";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { Button } from "@mui/material";
import { exportToExcel } from "react-json-to-excel";

import ThunksDrivers from "../../../redux/actions/thunks/drivers";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import {
  emptyValidation,
  emailValidation,
  IBANValueValidation,
} from "../../../validations";
import ThunksUsers from "../../../redux/actions/thunks/users";
import ThunksNetworks from "../../../redux/actions/thunks/networks";
import CompanyDetails from "../../../components/commons/CompanyDetails";
import { result } from "lodash";
import ItemList from "../../../components/commons/ItemList";
import EditMultipleValue from "../../../components/commons/EditMultipleValue";

function Drivers({
  getDrivers,
  loading,
  response,
  changeDriversRole,
  status,
  error,
  UnblockDriver,
  BlockDriver,
  updateStar,
  deleteDriver,
  getNetworks,
  networks,
}) {
  const [nets, setNets] = useState({});
  const [operationMessage, setOperationMessage] = useState("");
  useEffect(() => {
    getDrivers();
    getNetworks();
    getNetworkNameObject(networks);
  }, []);

  const getNetworkNameObject = (networks) => {
    let temp = [];
    for (let i in networks) {
      let name = networks[i].name;
      temp.push(name);
    }
    let result = temp.reduce((obj, network) => {
      obj[network] = network;
      return obj;
    }, {});
    setNets(result);
  };

  useEffect(() => {
    if (loading) getDrivers();
  }, [loading]);

  const columns = [
    {
      title: "Name",
      field: "displayName",
      validate: (rowData) => {
        return emptyValidation(rowData, "displayName");
      },
    },
    {
      title: "Network Name",
      // field: "Networks[0].name",
      field: "Networks",
      // lookup: nets,
      render: (rowData) => {
        return (
          <>
            <ItemList list={rowData.networks} />
          </>
        );
      },
      editComponent: (props) => {
        return <EditMultipleValue props={props} />;
      },
    },
    {
      title: "Full Name",
      field: "fullName",
      validate: (rowData) => {
        return emptyValidation(rowData, "fullName");
      },
    },
    {
      title: "Email",
      field: "email",
      validate: (rowData) => {
        return emailValidation(rowData, "email");
      },
    },
    {
      title: "IBAN",
      field: "IBAN",
      validate: (rowData) => {
        return IBANValueValidation(rowData, "IBAN");
      },
    },
    { title: "Address", field: "address" },
    {
      title: "Phone Number",
      field: "phoneNumber",
      validate: (rowData) => {
        return emptyValidation(rowData, "phoneNumber");
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: { available: "Available", occupied: "Occupied" },
    },

    {
      title: "Role",
      field: "role",
      lookup: { Dispatcher: "Dispatcher", Driver: "Driver" },
    },
    {
      title: "OS",
      field: "operatingSystem",
      editable: "never",
      lookup: { android: "Android", ios: "iOS" },
    },
    {
      title: "",
      field: "block",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
      },
      sort: false,
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {rowData.star ? (
                <StarIcon
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateStar(rowData.id, { star: false });
                    getDrivers(false);
                  }}
                />
              ) : (
                <StarBorderOutlinedIcon
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateStar(rowData.id, { star: true });
                    getDrivers(false);
                  }}
                />
              )}
              {rowData.block ? (
                <BlockOutlinedIcon
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={async () => {
                    await UnblockDriver(rowData.id);
                    setOperationMessage("Driver Unblocked");
                    getDrivers(false);
                  }}
                />
              ) : (
                <BlockOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await BlockDriver(rowData.id);
                    setOperationMessage("Driver blocked");
                    getDrivers(false);
                  }}
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
  const handleDownload = () => {
    let result = response.map((obj) => {
      const {
        id,
        passwordResetToken,
        passwordTokenExpire,
        isDeleted,
        tableData,
        pushToken,
        networkId,
        ...rest
      } = obj;
      //display multiple network names in the Excel file's "Networks" column.
      const networkNames = obj["networks"]
        .map((network) => network.name)
        .join(", ");
      return { ...rest, "Network Name": networkNames };
    });
    exportToExcel(result, "DriverLists");
  };

  return (
    <div
      style={{
        width: "full",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Button
        style={{
          border: "solid 1px black",
          marginTop: "10px",
          width: "fit-content",
          color: "#1976d2",
        }}
        onClick={() => handleDownload()}
      >
        Download Driver List
      </Button>
      <MaterialTable
        style={{ minWidth: "100%" }}
        icons={tableIcons}
        isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={response}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                changeDriversRole(oldData.id, newData);
                let successMessage = "";
                if (oldData.role !== newData.role) {
                  successMessage = "Role has been updated";
                } else {
                  successMessage = "Driver data has been updated";
                }
                setOperationMessage(successMessage);
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                const index = oldData.tableData.id;
                await deleteDriver(oldData.id);
                await getDrivers();
                resolve();
              }, 1000);
            }),

          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              console.log("newValue: " + newValue);
              setTimeout(resolve, 1000);
            });
          },
        }}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Drivers"
        detailPanel={[
          {
            icon: ArrowRight,
            tooltip: "Show Details",
            render: (rowData) => {
              return <CompanyDetails rowData={rowData} />;
            },
          },
        ]}
      />
      {error.status && <ErrorSnack errorMsg={error.message} />}
      {operationMessage && (
        <ErrorSnack
          reset={() => setOperationMessage("")}
          success
          errorMsg={operationMessage}
        />
      )}
    </div>
  );
}

const mapState = (state) => ({
  response: state.drivers.response.data,
  loading: state.drivers.loading,
  error: state.drivers.error.status,
  networks: state.networks.response.data,
});

const mapDispatch = {
  getDrivers: ThunksDrivers.Drivers,
  BlockDriver: ThunksDrivers.BlockDriver,
  UnblockDriver: ThunksDrivers.UnblockDriver,
  changeDriversRole: ThunksDrivers.Role,
  updateStar: ThunksUsers.updateStar,
  deleteDriver: ThunksDrivers.deleteDriver,
  getNetworks: ThunksNetworks.Networks,
};

export default connect(mapState, mapDispatch)(Drivers);
