import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CreateNetworkModal from "./CreateNetworkModal";
import MOButton from "../../../components/commons/MOButton";
import { Box } from "@material-ui/core";
import CreateNetworkModalForm from "./CreateNetworkModalForm";

function AddNetwork({ from, to, driver, loadData, initalLoad }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          textTransform: "none",
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Add Network
      </Button> */}
      <Box marginTop={"12px"} marginBottom={"12px"} textAlign={"end"}>
        <MOButton title="Add Network" onClick={handleClickOpen} />
      </Box>

      <CreateNetworkModalForm open={open} onClose={handleClose} />
      {/* <CreateNetworkModal open={open} onClose={handleClose} /> */}
    </div>
  );
}

export default AddNetwork;
