import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import ArrowRight from "@material-ui/icons/ArrowRight";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import DetailTable from "./DetailTable";
import ThunksNetworks from "../../../redux/actions/thunks/networks";
import ThunksVehiclesListing from "../../../redux/actions/thunks/vehicles-listing";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import AddNetwork from "./AddNetworks";
import "./index.css"
import { useState } from "react";

function ManageNetworks({
  getAllNetworks,
  response,
  loading,
  deleteNetwork,
  error,
}) {
  const [ deleted, setDeleted] = useState(false);
  useEffect(() => {
    getAllNetworks();
  }, []);
  useEffect(() => {
    if (loading) getAllNetworks();
  }, [loading]);
  return (
    <>
      <AddNetwork/>
      <MaterialTable
        icons={tableIcons}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        columns={[
          { title: "Display Name", field: "name" },
          { title: "Country Name", field: "country" },
          { title: "City Name",field:"city"},
          { title: "Total Drivers Within Network", field: "totalDrivers" },
          { title: "Total Dispatchers Within Network", field: "totalDispatchers" },
          { title: "Support Phone Nr", field: "supportNumber" },
          { title: "Country Code", field: "countryCode" },
        ]}
        data={response}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: "Delete Vehicle Brand",
            onClick: async(event, rowData) => {

              if (window.confirm("Are you sure to delete?")) {
                let result = await deleteNetwork(rowData.id);
                if(result){
                  setDeleted(true)
                }
              }
              
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Networks"
        detailPanel={[
          {
            icon: ArrowRight,
            tooltip: "Show Details",
            render: (rowData) => {
              return <DetailTable rowData={rowData} />;
            },
          },
        ]}
      />
      {deleted && <ErrorSnack success errorMsg={"Successfully removed "} />}
    </>
  );
}

const mapState = (state) => ({
  response: state.networks.response.data,
  loading: state.networks.loading,
  error: state.vehiclesListing.error,
});

const mapDispatch = {
  getAllNetworks: ThunksNetworks.Networks,
  deleteNetwork: ThunksNetworks.deleteNetwork,
};

export default connect(mapState, mapDispatch)(ManageNetworks);