import axios from "axios";
type SignUpBody = {
  displayName: string;
  email: string;
  password: string;
  companyName: string;
  country: string;
  city: string;
  address: string;
  addressLineTwo?: string;
  postalCode: string;
  activeCity: string;
  activeCountry: string;
  IBAN: string;
};
type VehicleAddBody = {
  brandName: string;
};
type CustomerAddBody = {
  email: string;
};
type CarAddBody = {
  carName: string;
  brandId: any;
  year: any;
  category: any;
  seatingCapacity: any;
};
type Approve = {
  id: any;
  user: {
    displayName: string;
  };
};
type Reject = {
  reason: any;
};
type BookingBody = {
  pickup: {
    name: String;
    coordinates: any;
  };
  destination: {
    name: String;
    coordinates: any;
  };
  dateTime: string;
  noOfPeople: number;
  carType: string;
  fare: number;
  dispatcherId: string;
  guestName: string;
  guestPhoneNumber: string;
  flightNumber: string;
  nameCaller: string;
  paymentType: any;
};
type ForgotPassword = {
  email: any;
};

type CustomerLogin = {
  email: string;
  password: string;
};

export type Requests = {
  login: (companyName: string, password: string) => any;
  signup: (body: SignUpBody) => any;
  getTaxiCompanies: () => any;
  getOnboardingListing: (orderFilter:any) => any;
  getVehiclesListing: () => any;
  postVehicle: (body: VehicleAddBody) => any;
  postCar: (body: CarAddBody) => any;
  approve: (body: Approve) => any;
  reject: (id: any, body: Reject) => any;
  getBookings: () => any;
  getDispatchers: () => any;
  // Dispatcher in edit and duplicate booking in web should be removed
  // getDispatchersByBookingService: (id: any) => any;
  deleteBrand: (id: any) => any;
  updateBrand: (id: string, obj: any) => any;
  createBooking: (body: BookingBody) => any;
  updateCar: (id: string, obj: any) => any;
  deleteCar: (id: string) => any;
  getPlanningBookings: () => any;
  getHistoryBookings: () => any;
  updateBooking: (id: string, obj: any) => any;
  cancelPendingBooking: (id: string) => any;
  cancelBooking: (obj: any) => any;
  getDrivers: () => any;
  changeDriverRole: (id: any, payload: any) => any;
  getTypeBookings: (
    type: string,
    page: any,
    limit: any,
    searchText: any
  ) => any;
  forgotPassword: (obj: ForgotPassword) => any;
  getCustomers: () => any;
  addCustomer: (body: CustomerAddBody) => any;
  updateOrgCustomer: (body: any) => any;
  sendEmailOrgCustomer: (body: any) => any;
  customerLogin: (body: CustomerLogin) => any;
  updateCustomer: (obj: any) => any;
  changeCustomerPassword: (obj: any) => any;
  getCustomerBookings: (type: string, offset: any, limit: any, searchText: string) => any;
  createCustomerBooking: (body: any) => any;
  getCustomerDispatchers: (
    type: string | null,
    customerId: string | null,
    integrationType: string | null,
  ) => any;
  assignCustomerDispatcher: (obj: any) => any;
  updateCustomerBooking: (id: string, obj: any) => any;
  bookingIntegration: (id: string, obj: any) => any;
  removeBookingIntegration: (id: string, type: string) => any;
  getBookingIntegration: (type: any) => any;
  getIntegrationDispatchers: (type: string)=> any;
  postIntegrationDispatchers: (obj: any) => any;
  updateOnboarding: (obj: any) => any;
  CheckDisplayName: (obj: any) => any;
  getImage: () => any;
  updateCommissionRoundable: (obj: any) => any;
  getBookingIntegrationDetails: () => any;
  postIntegrationDispatcher: (obj: any) => any;
  deleteIntegrationDispatchers: (obj: any) => any;
  createSetting: (obj: any) => any;
  getLatestTermsAndConditions: () => any;
  updateTermsAndConditions: (obj: any) => any;
  getSetting: (obj: any) => any;
  blockDispatcher: (obj: any) => any;
  blockDriver: (obj: any) => any;
  unblockDispatcher: (obj: any) => any;
  unblockDriver: (obj: any) => any;
  updateStar: (id: any, obj: any) => any;
  updateGhost: (id: any, obj: any) => any;
  getDriverEventsBybooking: (id: any) => any;
  getBookingdriver: (id: any) => any;
  getCommissions: (value:any) => any;
  getCustomerCommissions: (id: string) => any;
  addCommission: (body: any) => any;
  addCustomerCommission: (body: any) => any;
  updateCommission: (id: any, obj: any) => any;
  deleteCommission: (id: any) => any;
  getPayouts: (orgId: any, range: string, zoneOffset: any) => any;
  getSinglePayout: (
    orgId: any,
    range: string,
    driverId: any,
    zoneOffset: any
  ) => any;
  saveAdditionalPayment: (objects: any) => any;
  updateAdditionalPayment: (objects: any) => any;
  saveAdditionalPayin: (obj: any) => any;
  updateAdditionalPayin: (obj: any) => any;
  updateBookingOrg: (id: any, obj: any) => any;
  updateAdditionalBookingOrg: (id: any, obj: any) => any;
  deleteAdditionalBookingOrg: (id: any) => any;
  createPayment: (objects: any, zoneOffset: any) => any;
  getPaymentInvoiceDetail: (invoiceId: any) => any;
  getPaymentByYear: (year: any) => any;
  getPayments: (page: any, limit: any, searchText: any) => any;
  getPaidPaymentsByTimeRange: (startDate: any, endDate: any, scope: string) => any;
  createCompany: (body: any) => any;
  updateCompany: (id: any, obj: any) => any;
  createAdditionalBooking: (objects: any, zoneOffset: any) => any;
  searchByBookingId: (id: any, zoneOffset: any) => any;
  generatedXML: (data: any) => any;
  previewPayment: (objects: any) => any;
  getAuthURL: (objects: any) => any;
  createSheet: (objects: any) => any;
  importSheet: (objects: any) => any;
  getAssignedPaginated: (
    type: string,
    page: any,
    limit: any,
    searchText: any
  ) => any;
  getDisplayNameByUserId: (id: string) => any;
  getDisplayNameByClientId: (id: string) => any;
  deleteDriver: (obj: any) => any;
  getPassengers: (searchText: String) => any;
  updatePassengers: (obj: any) => any;
  deletePassenger: (obj: any) => any;
  createPassenger: (obj: any) => any;
  getAllAuthorities: () => any;
  createOrganisationUser: (data: any) => any;
  getOrgUsers: () => any;
  deleteUser: (id: any) => any;
  updateUserAuthorizations: (data: any) => any;
  updateOrgUser: (data: any) => any;
  getAuthorities: (data: any) => any;
  blockUser: (data: any) => any;
  updatePass: (data: any) => any;
  //
  getClientAllAuthorities: () => any;
  createClientUser: (data: any) => any;
  getClientUsers: (searchText: String) => any;
  deleteClientUser: (id: any) => any;
  updateClientUserAuthorizations: (data: any) => any;
  updateClientUser: (data: any) => any;
  getClientAuthorities: (data: any) => any;
  blockClientUser: (data: any) => any;
  updateClientPass: (data: any) => any;
  getTotalAndAssignedRides: () => any;
  getBookingsByCustomerId: (id: any, start: any, end: any) => any;
  getCompanies: () => any;
  getBookingsByIntegrationId: (id: any, start: any, end: any, companyName: any) => any;
  getReportForOrganization: (data: any) => any;
  getCompaniesAndCustomersByOrgId: (start: any, end: any) => any;
  getNetworks: () => any;
  deleteNetwork: (data: any) => any;
  createNetworks: (data: any) => any;
  getDriversForNetwork: (data: any) => any;
  getDispatchersForNetwork: (data: any) => any;
  getAllForNetwork: (data: any) => any;
  getNetworkById: (data: any) => any;
  getNetworkForOrganization: (id: any) => any;
  getNetworkByNetworkId: (id: any) => any;
  getSubAdminAllAuthorities: () => any;
  createSubAdmin: (obj: any) => any;
  getSubAdmins: () => any;
  deleteSubAdmin: (id: any) => any;
  updateSubAdminAuthorizations: (obj: any) => any;
  updateSubAdmin: (obj: any) => any;
  getSubAdminAuthorities: (obj: any) => any;
  blockSubAdmin: (obj: any) => any;
  updateSubAdminPass: (obj: any) => any;
  getPayins: (orgId: any, range: string) => any;
  getPayinPayments: (isPaid: any, searchText: any) => any;
  getSinglePayin: (orgId: any, range: string, clientId: any) => any;
  createPayinPayment: (objects: any) => any;
  updatePayinPayment: (id: any) => any;
  previewPayinPayment: (objects: any) => any;
  getPaidPaymentByYear: (year: any) => any;
  getCustomerPayinPayments: (status: any, searchText: any, offset: any, limit: any) => any;
}
const mobioApi = (token: string = ""): Requests => {
  /* authenticated axios instance */
  const api: any = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 2 * 60 * 1000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const requests: Requests = {
    login: (displayName, password) =>
      api.post("/user/web/login", {
        displayName,
        password,
      }),

    signup: (body) => api.post("/user/web/signup", body),
    getTaxiCompanies: () => api.get("/admin/company/all"),
    getBookingsByCustomerId: (id, start, end) => api.get(`/company/customer/bookings/${id}/${start}/${end}`),
    getOnboardingListing: (orderFilter) => api.get(`/admin/driver/allOnboarding?orderFilter=${orderFilter}`),
    updateOnboarding: (obj) => api.put("/admin/driver/updateOnboarding", obj),
    getVehiclesListing: () => api.get("/admin/vehicleList"),
    postVehicle: (body) => api.post("/admin/brand/create", body),
    deleteBrand: (id) => api.delete(`/admin/brand/${id}`),
    updateBrand: (id, obj) => api.put(`/admin/brand/${id}`, obj),
    postCar: (body) => api.post("/admin/car/create", body),
    deleteCar: (id) => api.delete(`/admin/car/${id}`),
    updateCar: (id, obj) => api.put(`/admin/car/${id}`, obj),
    approve: (body) => api.put(`/admin/driver/verify/${body.id}`, body.user),
    reject: (id, obj) => api.put(`/admin/driver/reject/${id}`, obj),
    getBookings: () => api.get("/admin/booking"),
    getPlanningBookings: () => api.get(`/rides/planning`),
    getHistoryBookings: () => api.get(`/rides/history`),
    getAssignedPaginated: (type, page = "", limit = "", searchText = "") =>
      api.get(
        `/rides/assigned?page=${page}&limit=${limit}&searchText=${searchText}`
      ),
    getTypeBookings: (type, page = "", limit = "", searchText = "") =>
      api.get(
        `/rides/${type}/?page=${page}&limit=${limit}&searchText=${searchText}`
      ),
    getDispatchers: () => api.get("/admin/dispatcher/all"),
    // getDispatchersByBookingService: (id) => api.get(`/company/dispatcher/booking/${id}`),
    createBooking: (obj) => api.post("/company/booking/create", obj),
    getBookingsByIntegrationId: (id, start, end, companyName) => api.get(`/company/bookings/${id}/${start}/${end}/${companyName}`),
    updateBooking: (id, obj) => api.put(`/client/bookings/${id}`, obj),
    cancelBooking: (obj) => api.post("/rides/acknowledge-native", obj),
    cancelPendingBooking: (id) => api.put(`/rides/acknowledge/${id}`),
    getCustomerDispatchers: (
      type: string | null = null,
      customerId: string | null = null,
      integrationType: string | null = null
    ) =>
      api.get(
        type
          ? `/company/dispatchers?type=${type}&customerId=${customerId}`
          : `/company/dispatchers?integrationType=${integrationType}`
      ),
    getDrivers: () => api.get("/admin/drivers"),
    changeDriverRole: (id, payload) =>
      api.put(`/admin/driver-upgrade/${id}`, payload),
    forgotPassword: (obj) => api.post("/user/password-forgot", obj),
    getCustomers: () => api.get("/client"),
    getCompanies: () => api.get("/company/"),
    getCompaniesAndCustomersByOrgId: (start, end) => api.get(`/company/customer/${start}/${end}`),
    addCustomer: (body) => api.post("/client", body),
    updateOrgCustomer: (body) => api.put("/client/update", body),
    sendEmailOrgCustomer: (body) => api.put("/client/send-email", body),
    customerLogin: (body) => api.post("/client/login", body),
    updateCustomer: (obj) => api.put("/client/profile", obj),
    changeCustomerPassword: (obj) => api.put("/client/", obj),
    getCustomerBookings: (type, offset, limit, searchText = "") =>
      api.get(`/client/bookings/${type}/?offset=${offset}&limit=${limit}&searchText=${searchText}`),
    createCustomerBooking: (body) => api.post("/client/bookings", body),
    assignCustomerDispatcher: (obj) => api.put("/client/dispatcher", obj),
    updateCustomerBooking: (id, obj) => api.put(`/client/bookings/${id}`, obj),
    bookingIntegration: (id, obj) =>
      api.post(`/company/${id}/integration/`, obj),
    removeBookingIntegration: (id, type) =>
      api.post(`/company/${id}/disconnect-integration/${type}`),
    getBookingIntegration: (type) => api.get(`/user/integration/${type}`),
    getBookingIntegrationDetails: () => api.get(`/user/integration-details`),
    getIntegrationDispatchers: (type) => api.get(`/integration/dispatchers/${type}`),
    updateCommissionRoundable: (obj) => api.put(`/integration`, obj),
    postIntegrationDispatchers: (obj) =>
      api.post(`/integration/dispatchers`, obj),
    postIntegrationDispatcher: (obj) =>
      api.post(`/integration/dispatcher`, obj),
    deleteIntegrationDispatchers: (obj) =>
      api.put(`/integration/dispatchers`, obj),
    CheckDisplayName: (obj) => api.post("/user/check/display-name", obj),
    getImage: () => api.get("/user/getImage"),
    getDisplayNameByUserId: (id) => api.post("/user/display-name", id),
    getDisplayNameByClientId: (id) => api.post("/client/display-name", id),
    createSetting: (obj) => api.post("/admin/setting/create", obj),
    updateTermsAndConditions: (obj) => api.post("/admin/setting/updateTermsAndConditions", obj),
    updatePassengers: (obj) => api.post("/passenger/update", obj),
    deletePassenger: (obj) => api.post("/passenger/delete", obj),
    createPassenger: (obj) => api.post("/passenger/create", obj),
    getLatestTermsAndConditions: () => api.get("/admin/setting/getLatestTermsAndConditions"),
    getSetting: (obj) => api.get(`/admin/setting/${obj.keyName}`),
    blockDispatcher: (id) => api.put(`/admin/dispatcher/block/${id}`),
    unblockDispatcher: (id) => api.put(`/admin/dispatcher/unblock/${id}`),
    blockDriver: (id) => api.put(`/admin/driver/block/${id}`),
    unblockDriver: (id) => api.put(`/admin/driver/unblock/${id}`),
    updateStar: (id, obj) => api.put(`/user/star/${id}`, obj),
    updateGhost: (id, obj) => api.put(`/user/ghost/${id}`, obj),
    getDriverEventsBybooking: (id) => api.get(`/driver-event/${id}`),
    getBookingdriver: (id) => api.get(`/user/bookingDriver/info/${id}`),
    getCommissions: (value) => api.get(`/integration/commission/${value}`),
    getCustomerCommissions: (id) => api.get(`/integration/customer/commission/${id}`),
    addCommission: (body) => api.post(`/integration/commission`, body),
    addCustomerCommission: (body) => api.post("/integration/customer/commission", body),
    updateCommission: (id, obj) => api.put(`/integration/commission/${id}`, obj),
    deleteCommission: (id) => api.delete(`/integration/commission/${id}`),
    getPayouts: (orgId, range, zoneOffset) =>
      api.get(`/rides/payouts/${orgId}/${range}/${zoneOffset}`),
    getSinglePayout: (orgId, range, driverId, zoneOffset) =>
      api.get(`/rides/payouts/${orgId}/${range}/${driverId}/${zoneOffset}`),
    saveAdditionalPayment: (objects) =>
      api.post(`/payment/additional`, { objects }),
    updateAdditionalPayment: (objects) =>
      api.put(`/payment/additional`, { objects }),
    saveAdditionalPayin: (objects: any) => api.post(`/payment/payin/additional`, { objects }),
    updateAdditionalPayin: (objects: any) => api.put(`/payment/payin/additional`, { objects }),
    createPayment: (objects) =>
      api.post(`/payment`, objects),
    getPaymentInvoiceDetail: (invoiceId: any) =>
      api.get(`/payment/get-invoice-detail/${invoiceId}`),
    searchByBookingId: (bookingId, zoneOffset) =>
      api.get(`/payment/search/byBookingId/${bookingId}/${zoneOffset}`),
    getPayments: (page = "", limit = "", searchText = "") =>
      api.get(`/payment?page=${page}&limit=${limit}&searchText=${searchText}`),
    getPaidPaymentsByTimeRange: (startDate = null, endDate = null, scope = "") =>
      api.get(`/payment/paidPayment-timeRange?startDate=${startDate}&endDate=${endDate}&scope=${scope}`),
    getPaymentByYear: (year) => api.get(`/payment/byYear/${year}`),
    generatedXML: (object) => api.post(`/payment/generateXML`, object),
    updateBookingOrg: (id, obj) => api.put(`/rides/${id}`, obj),
    getAuthURL: (objects: any) => api.post(`/spread-sheet/auth-url`, objects),
    createSheet: (objects: any) => api.post(`/spread-sheet/create-sheet`, objects),
    importSheet: (objects: any) => api.post(`/spread-sheet/import-sheet`, objects),
    updateAdditionalBookingOrg: (id, obj) =>
      api.put(`/rides/additional/${id}`, obj),
    deleteAdditionalBookingOrg: (id) => api.delete(`/rides/additional/${id}`),
    createCompany: (body) => api.post(`/user/org`, body),
    updateCompany: (id, obj) => api.put(`/user/org/${id}`, obj),
    createAdditionalBooking: (obj) => api.post(`/company/booking/additional/create`, obj),
    getReportForOrganization: (obj) => api.post("/company/report/organization", obj),
    previewPayment: (objects) => api.post(`/payment/preview`, objects),
    deleteDriver: (obj) => api.post('admin/driver/delete', obj),
    getPassengers: (searchText = "") => api.get(`/passenger?searchText=${searchText}`),
    getAllAuthorities: () => api.get(`/org-users/AllAuthorities`),
    createOrganisationUser: (data) =>
      api.post(`/org-users/createOrgUser`, data),
    getOrgUsers: () => api.get(`/org-users/OrgUsers`),
    deleteUser: (id) => api.delete(`/org-users/deleteOrgUser/${id}`),
    updateUserAuthorizations: (data) =>
      api.post(`/org-users/updateUserAuthr`, data),
    updateOrgUser: (data) => api.post(`/org-users/updateOrgUser`, data),
    getAuthorities: (data) => api.post(`/org-users/getAuthorities`, data),
    blockUser: (data) => api.post(`/org-users/blockUser`, data),
    updatePass: (data) => api.post(`/org-users/updatePass`, data),
    //new
    getClientAllAuthorities: () => api.get(`/client-users/AllAuthorities`),
    createClientUser: (data) => api.post(`/client-users/createUser`, data),
    getClientUsers: (searchText = "") => api.get(`/client-users/clientUsers?searchText=${searchText}`),
    deleteClientUser: (id) => api.delete(`/client-users/deleteClientUser/${id}`),
    updateClientUserAuthorizations: (data) =>
      api.post(`/client-users/updateUserAuthr`, data),
    updateClientUser: (data) => api.post(`/client-users/updateClientUser`, data),
    getClientAuthorities: (data) =>
      api.post(`/client-users/getAuthorities`, data),
    blockClientUser: (data) => api.post(`/client-users/blockUser`, data),
    updateClientPass: (data) => api.post(`/client-users/updatePass`, data),
    getTotalAndAssignedRides: () => api.get(`/rides/totalOverAssigned`),
    createPayinPayment: (objects) => api.post(`/payment/payin`, objects),
    updatePayinPayment: (id) => api.put(`/payment/payin/${id}`),
    getNetworks: () => api.get("/network"),
    getDriversForNetwork: (id) => api.get(`/network/drivers/${id}`),
    getNetworkByNetworkId: (id) => api.get(`/network/individual/${id}`),
    getAllForNetwork: (data) => api.get(`/network/all/${data}`),
    getDispatchersForNetwork: (data) => api.get(`/network/dispatchers/${data}`),
    deleteNetwork: (networkId) => api.delete(`/network/${networkId}`),
    createNetworks: (data) => api.post("/admin/networks", data),
    getNetworkById: (data) => api.get(`/network/each/${data}`),
    getNetworkForOrganization: (id) => api.get(`/network/organization/${id}`),
    getSubAdminAllAuthorities: () => api.get(`/subAdmin/AllAuthorities`),
    createSubAdmin: (data) => api.post(`/subAdmin/createSubAdmin`, data),
    getSubAdmins: () => api.get(`/subAdmin/subAdmins`),
    deleteSubAdmin: (id) => api.delete(`/subAdmin/deleteSubAdmin/${id}`),
    updateSubAdminAuthorizations: (data) =>
      api.post(`/subAdmin/updateUserAuthr`, data),
    updateSubAdmin: (data) => api.post(`/subAdmin/updateSubAdmin`, data),
    getSubAdminAuthorities: (data) =>
      api.post(`/subAdmin/getAuthorities`, data),
    blockSubAdmin: (data) => api.post(`/subAdmin/blockUser`, data),
    updateSubAdminPass: (data) => api.post(`/subAdmin/updatePass`, data),
    getPayins: (orgId, range) => api.get(`/rides/payins/${orgId}/${range}`),
    getPayinPayments: (isPaid = false, searchText = "") => api.get(`/payment/payin?isPaid=${isPaid}&searchText=${searchText}`),
    getSinglePayin: (orgId, range, clientId) => api.get(`/rides/payins/${orgId}/${range}/${clientId}`),
    previewPayinPayment: (objects) => api.post(`/payment/payin-preview`, objects),
    getPaidPaymentByYear: (year) => api.get(`/payment/payin/byYear/${year}`),
    getCustomerPayinPayments: (status = "all", searchText = "", offset: any, limit: any) => api.get(`/payment/customer/payin?status=${status}&searchText=${searchText}&offset=${offset}&limit=${limit}`),
  };

  return requests;
};

export default mobioApi;
