import { jsPDF } from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { serviceLevels } from "./carsTypes";

export const PaymentInvoiceGenerate = (
  invoiceDetail,
  downloadStatus = true,
  orgImage,
  type = "arraybuffer"
) => {
  const { orgData, driver, invoiceNo, from, to, bookings, total, vatAmount } =
    invoiceDetail;

  const options = {
    format: "a3",
    orientation: "portrait",
    unit: "mm",
    format: [297, 420], // A3 dimensions in mm
  };
  const pdf = new jsPDF(options);

  // Invoice header
  pdf.setFont("Courier");
  //  pdf.text('Invoice', 105, 10, { align: 'center' });

  if (orgImage) {
    pdf.addImage(orgImage, "png", 50, 20, 34, 42); // Adjust image dimensions and position as needed
  }

  // Invoice From
  pdf.setFontSize(15);
  pdf.setFont(undefined, "bold");
  pdf.setTextColor(10, 1, 0)
  pdf.text("INVOICE FROM", 50, 75);
  pdf.setTextColor(102, 102, 102)
  pdf.setFontSize(12);
  pdf.setFont(undefined, "normal");
  pdf.text(driver.companyName || "", 50, 85);
  pdf.text(driver.companyAddress || "", 50, 90);
  pdf.text(
    `${driver.companyPostCode || ""} ${driver.companyCity || ""}`,
    50,
    95
  );

  // Invoice To
  pdf.setFontSize(15);
  pdf.setFont(undefined, "bold");
  pdf.setTextColor(10, 1, 0)
  pdf.text("INVOICE TO", 160, 20);
  
  pdf.setFontSize(15);
  pdf.setFont(undefined, "bold");
  pdf.text(orgData.companyName || "", 160, 30);
  pdf.setTextColor(102, 102, 102)
  pdf.setFontSize(12);
  pdf.setFont(undefined, "normal");
  pdf.text(orgData.companyAddress || "", 160, 40);
  //   pdf.text(`${orgData.companyPostCode || ''} ${orgData.companyCity || ''}`, 130, 45);
  let addressDisplay = "";
  if (orgData?.companyPostCode) {
    addressDisplay += orgData.companyPostCode;
  }

  if (orgData?.companyCity) {
    if (addressDisplay != "") {
      addressDisplay += "";
    }
    addressDisplay += orgData.companyCity;
  }

  if (orgData?.activeCountry) {
    if (addressDisplay != "") {
      addressDisplay += "";
    }

    if (orgData.companyCity) {
      addressDisplay += `, `;
    }

    addressDisplay += orgData.activeCountry;
  }

  //   pdf.text(`${orgData.companyPostCode || ''} ${orgData.companyCity || ''} ${orgData.companyCity ? ', ' : ''} ${orgData.activeCountry || ''}`, 129, 45);
  pdf.text(`${addressDisplay}`, 160, 45);
  // pdf.text(`VAT No: ${orgData.companyVATNumber || "n/a"}`, 160, 50);
  // pdf.text(`COC No: ${orgData.companyRegistrationNumber || "n/a"}`, 160, 55);
  // pdf.text(
  //   `Account Holder Name: ${orgData.bankAccountHolderName || "n/a"}`,
  //   160,
  //   60
  // );
  // pdf.text(`IBAN: ${orgData.IBAN || "n/a"}`, 160, 65);

  // Invoice number and date
  pdf.setFontSize(15);
  pdf.setFont(undefined, "bold");
  pdf.setTextColor(103, 102, 102);
  pdf.text(`Invoice ${invoiceNo || ""}`, 38, 122);

  pdf.setTextColor(102, 102, 102)
  pdf.setFontSize(12);
  pdf.setFont(undefined, "normal");
  // pdf.setFont(undefined, "bold");

  pdf.text(`From Date: `, 200, 122);
  pdf.setFont(undefined, "normal");
  pdf.text(`${moment(from).format("YYYY-MM-DD") || ""}`, 230, 122);
  // pdf.setFont(undefined, "bold");
  pdf.text(`To Date:`, 200, 127);
  pdf.setFont(undefined, "normal");
  pdf.text(`${moment(to).format("YYYY-MM-DD") || ""}`, 230, 127);

  //   Table
  // Invoice table
  const tableData = bookings.map((booking, index) => [
    (index + 1).toString(),
    booking.date || "",
    booking.guestName || "",
    booking.bookingReference || booking.refId || "",
    booking.carType ? serviceLevels[booking.carType] : "n/a",
    `€${(booking.finalPrice ? booking.finalPrice.toFixed(2) : 0).toLocaleString(
      "en-US",
      { minimumFractionDigits: 2 }
    )}`,
    `${orgData.vatPercentage || "0"} %`,
  ]);

  // pdf.autoTable({
  //   head: [["#", "Date", "Name", "Booking", "Service Level", "Amount", "VAT"]],
  //   body: tableData,
  //   startY: 145,
  //   // startX:10,
  //   // headStyles: {
  //   //   fontStyle: 'bold',
  //   //   fillColor: [255, 255, 255],
  //   //   textColor: [78, 53, 73], //Black
  //   //   lineWidth: 0.1,
  //   //   lineColor: 'black',
  //   // },
  //   // bodyStyles: {
  //   //   fillColor: [255, 255, 255],
  //   //   textColor: [128, 128, 128], //Black
  //   //   lineWidth: 0,
  //   //   lineColor: 'black',
  //   // },
  //   // theme: 'plain',

  //   headStyles: {
  //     fontStyle: "bold",
  //     fillColor: [255, 255, 255],
  //     textColor: [78, 53, 73], //Black
  //     lineWidth: 0,
  //     lineColor: "black",
  //   },
  //   bodyStyles: {
  //     fillColor: [255, 255, 255],
  //     textColor: [78, 53, 73], //Black
  //     lineWidth: 0.1,
  //     lineColor: "gray",
  //   },
  //   theme: "plain",
  //   styles: {
  //     lineWidth: 0,
  //   },

  //   didParseCell: function (data) {
  //     data.cell.styles.font = "courier";
  //     data.cell.styles.fontSize = 11;
  //   },
  // });

  // Invoice summary
// v2
  // pdf.autoTable({
  //   head: [["#", "Date", "Name", "Booking", "Service Level", "Amount", "VAT"]],
  //   body: tableData,
  //   startY: 145,
  //   headStyles: {
  //     fontStyle: "bold",
  //     fillColor: [255, 255, 255],
  //     textColor: [78, 53, 73], //Black
  //     lineWidth: 0.1,
  //     lineColor: "gray",
  //   },
  //   bodyStyles: {
  //     fillColor: [255, 255, 255],
  //     textColor: [78, 53, 73], //Black
  //     lineWidth: 0.1,
  //     lineColor: "gray",
  //   },
  //   theme: "plain",
  //   styles: {
  //     lineWidth: 0,
  //   },
  //   didParseCell: function (data) {
  //     data.cell.styles.font = "courier";
  //     data.cell.styles.fontSize = 11;
  //   },
  // });


  // v3
  
  // working fine
//   pdf.autoTable({
//     head: [["#", "Date", "Name", "Booking", "Service Level", "Amount", "VAT"]],
//     body: tableData,
//     startY: 145,
//     headStyles: {
//         fontStyle: "bold",
//         fillColor: [255, 255, 255],
//         textColor: [78, 53, 73], // Black
//         lineWidth: 0,
//         lineColor: "gray",
//     },
//     margin: { left: 40,right:40 },
//     bodyStyles: {
//         lineWidth: 0,
//         lineColor: [169, 169, 169], // Light gray
//     },
//     theme: "plain",
//     didParseCell: function (data) {
//           data.cell.styles.font = "courier";
//           data.cell.styles.fontSize = 12;
//         },
//     didDrawPage: function (data) {
//       // let bottom=137;
//       let bottom=145;
       
//         // Loop through each row in the body and draw the bottom border
//         pdf.line(36, bottom, 256, bottom);

//         data.table.body.forEach((row, rowIndex) => {
//             var rowHeight = row.height || data.row.height;
//             bottom += rowHeight;

//             // if (rowIndex !== data.table.body.length - 1) {
//             //     // Draw a line at the bottom of the row except for the last row
//             //     pdf.line(12, bottom, 286, bottom);
//             // }
//             pdf.line(36, bottom, 256, bottom);
//         });
//         // add in the last
//         pdf.line(36, bottom+8, 256, bottom+8);
//     },
// });


pdf.autoTable({
  head: [["#", "Date", "Name", "Booking", "Service Level", "Amount", "VAT"]],
  body: tableData,
  startY: 135,
  headStyles: {
      fontSize:13,
      fontStyle: "bold",
      fillColor: [255, 255, 255],
      textColor: [10, 1, 0], // Black
      lineWidth: 0,
      lineColor: "gray",
  },
  margin: { left: 40, right: 40 },
  bodyStyles: {
      lineWidth: 0.1,
      lineColor: [169, 169, 169], // Light gray
      textColor:[12, 16, 12]
  },
  theme: "plain",
  didParseCell: function (data) {
      data.cell.styles.font = "courier";
      data.cell.styles.fontSize = 12;
      // data.cell.styles.fontStyle = "bold";
  },
  // didDrawPage: function (data) {
  //   // let bottom=137;
  //   console.log("data",data)
  //   let bottom=135;
     
  //     // Loop through each row in the body and draw the bottom border
  //     // pdf.line(36, bottom, 256, bottom);

  //     data.table.body.forEach((row, rowIndex) => {
  //         var rowHeight = row.height || data.row.height;
  //         bottom += rowHeight;

  //         // if (rowIndex !== data.table.body.length - 1) {
  //         //     // Draw a line at the bottom of the row except for the last row
  //         //     pdf.line(12, bottom, 286, bottom);
  //         // }
  //         pdf.line(36, bottom, 256, bottom);
  //     });
  //     // add in the last
  //     pdf.line(36, bottom+8, 256, bottom+8);
  // },
});











  pdf.setTextColor(78, 53, 73);
  pdf.setFont(undefined, "bold");
  pdf.text(`Subtotal excl. VAT:`, 154, pdf.autoTable.previous.finalY + 10);
  pdf.setFont(undefined, "normal");
  pdf.text(
    `€ ${(total - vatAmount).toFixed(2)}`,
    212,
    pdf.autoTable.previous.finalY + 10
  );

  pdf.setFont(undefined, "bold");
  pdf.text(
    `${orgData.vatPercentage || "0"}% VAT:`,
    180,
    pdf.autoTable.previous.finalY + 20
  );
  pdf.setFont(undefined, "normal");
  pdf.text(
    `€ ${vatAmount.toFixed(2)}`,
    212,
    pdf.autoTable.previous.finalY + 20
  );

  pdf.line(
    150,
    pdf.autoTable.previous.finalY + 24,
    256,
    pdf.autoTable.previous.finalY + 24
  );

  pdf.setFont(undefined, "bold");
  pdf.text(`Total:`, 184, pdf.autoTable.previous.finalY + 30);
  pdf.setFont(undefined, "bold");
  pdf.text(`€ ${total.toFixed(2)}`, 212, pdf.autoTable.previous.finalY + 30);
  pdf.setFont(undefined, "normal");

  //   footer
  // const footerText = `Invoice issued on behalf of ${driver.companyName || ""} by ${orgData.companyName || ""} Through Mobio Technologies B.v.
  // The recipient will receive €${total.toFixed(2).replace(".", ",")} stating the invoice ${invoiceNo || ""} at ${driver.IBAN || ""}.`;

  // const totalPages = pdf.internal.getNumberOfPages();

  // for (let i = 1; i <= totalPages; i++) {
  //     pdf.setPage(i);
  //     pdf.text(footerText, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 10, { align: 'center' });
  // }

  const footerText = `Invoice issued on behalf of ${
    driver.companyName || ""
  } by ${orgData.companyName || ""} Through Mobio Technologies B.v.
    The recipient will receive €${total
      .toFixed(2)
      .replace(".", ",")} stating the invoice ${invoiceNo || ""} at ${
    driver.IBAN || ""
  }.`;

  pdf.setFontSize(11);
  const totalPages = pdf.internal.getNumberOfPages();
  const lastPageHeight = pdf.internal.pageSize.getHeight() - 10;

  pdf.setLineWidth(0.5); // Adjust line width as needed
  pdf.line(
    40,
    lastPageHeight - 5,
    pdf.internal.pageSize.getWidth() - 30,
    lastPageHeight - 5
  );

  pdf.setPage(totalPages);
  pdf.text(footerText, pdf.internal.pageSize.getWidth() / 2, lastPageHeight, {
    align: "center",
  });

  //   save
  if (downloadStatus) {
    pdf.save(`${invoiceNo}.pdf`);
  } else {
    let getPdfData = null;
    if (type == "arraybuffer") {
      getPdfData = pdf.output("arraybuffer");
    } else if (type == "blob") {
      const pdfBlob = pdf.output("blob");
      getPdfData = URL.createObjectURL(pdfBlob);
      // return url;
    }
    return getPdfData;
    // return pdf.output('arraybuffer')
    // return pdf.save();
  }
};
