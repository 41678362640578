import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import worldTransfer from "../../../assets/icons/worldTransfer.png";
import { connect } from "react-redux";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import mobioApi from "../../../services";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
    marginLeft: '10px'
  },
  root: {
    // justifyContent: "center",
    // alignItems: "center",
  },

  title: {
    // fontSize: 24,
    // fontWeight: 600,
    // textAlign: "center",
    marginLeft: 80,
    marginRight: 80,
    // marginBottom: 28,
  },
  input: {
    display: "flex",
    flexDirection: "column",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
const WorldTransferIntegration = ({
  connectSuccess,
  connectError,
  reset,
  error,
  message,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classes.mainCard}
      >
        <Card className={classes.root}>
          <CardContent>
            <Grid container justifyContent="center" alignItems="center" style={{ height: "120px" }}>
              <img
                className={classes.title}
                src={worldTransfer}
                style={{ height: "60px", width: "220px" }}
              />
            </Grid>

          </CardContent>
          <Grid container justifyContent="center" alignItems="center">
          </Grid>
        </Card>
      </Grid>
      {connectSuccess && (
        <ErrorSnack success reset={() => reset()} errorMsg={message} />
      )}
      {connectError && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
    </>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
  token: state.userInfo.token,
  error: state.bookingIntegration.error,
  connectSuccess: state.bookingIntegration.response.status,
  message: state.bookingIntegration.response.data,
  loader: state.bookingIntegration.loading,
  connectError: state.bookingIntegration.error.status,
});

const mapDispatch = {
  bookingIntegration: ThunksBooking.BookingIntegration,
  removeBookingIntegration: ThunksBooking.RemoveBookingIntegration,
  reset: Local.BookingIntegration.api.reset,
};

export default connect(mapState, mapDispatch)(WorldTransferIntegration);