import { Link } from "react-router-dom";
import BookingIntegration from "./BookingIntegration";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import WorldTransferIntegration from "./WorldTransferIntegration";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    flexDirection: "row",
  },
}));
const Integration = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.mainCard}
    >
      <Link to="/dashboard/integration/api-key?integration=booking.com">
        <BookingIntegration />
      </Link>
      <Link to="/dashboard/integration/api-key?integration=worldTransfer.com">
        <WorldTransferIntegration />
      </Link>
    </Grid>
  );
};

export default Integration;
