import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { countries } from '../../../helpers/countries';
import { useFormikContext } from 'formik';
export default function CountrySelect({ setCountryName,setCountryCode, setCode}) {
  const formik= useFormikContext();
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 260 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      onChange={(event, value)=>{
        console.log("here is value", value)

        if(value){
          setCode(value.code)
          setCountryName(value.label);
          setCountryCode(value.phone)
          if(formik){
              formik.setValues({
              ...formik.values, // Keep other fields unchanged
              country: value.label,
              countryCode: value.phone,
            });
          }
        
        }else{
          setCountryCode("");
          setCountryName("");
          setCode("")
          if(formik){
              formik.setValues({
              ...formik.values, // Keep other fields unchanged
              country: "",
              countryCode: "",
            });
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          sx={{ height : 40}}
        />
      )}
    />
  );
}