import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  InputLabel,
  Box,
} from "@material-ui/core";
import Input from "../../../components/commons/Input";
import CountrySelect from "./CountrySelect";
import GlobalLocationView from "../../../components/commons/GlobalLocationField";
import ThunksNetworks from "../../../redux/actions/thunks/networks";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import MOButton from "../../../components/commons/MOButton";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    marginRight: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
}));

function CreateNetworkModalForm({ open, onClose, createNetwork }) {
  const classes = useStyles();
  const [countryName, setCountryName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [city, setCity] = useState(false);
  const [code, setCode] = useState("");

  const handleSubmit = async (values) => {
    try {
      let result = await createNetwork(values);
      if (result.state == true) {
        onClose();
        setSuccess(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <DialogTitle className={classes.heading} id="simple-dialog-title">
              Add Network
            </DialogTitle>
          </Grid>

          {/* Formik Form handling start */}
          <Formik
            initialValues={{
              name: "",
              country: "",
              supportNumber: "",
              countryCode: "",
              documentImage: "",
              lat: "",
              lng: "",
              redius: "",
              city: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Network Name is required.";
              }
              if (!values.country) {
                errors.country = "Country is required.";
              }
              if (!values.supportNumber) {
                errors.supportNumber = "Support Number  is required.";
              }
              if (!values.city) {
                errors.city = "City is required.";
              }
              if (!values.lat) {
                errors.lat = "Please select the Airport .";
              }
              if (!values.redius) {
                errors.redius = "Radius is required.";
              }

              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                {/* Name and Country */}
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <Input
                      label="Network Name"
                      name="name"
                      size="small"
                      fullWidth
                      style={{ marginTop: 0, marginRight: 10, width: 290 }}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                    <ErrorMessage
                      name={`name`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </div>
                  <Box width={"300px"}>
                    <CountrySelect
                      setCountryName={setCountryName}
                      setCountryCode={setCountryCode}
                      setCode={setCode}
                    />
                    <ErrorMessage
                      name={`country`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Box>
                </Grid>
                {/* Whats Number and country code */}
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Input
                      label="Whatsapp Number"
                      name="supportNumber"
                      size="small"
                      fullWidth
                      style={{ marginTop: 0, width: 275, marginRight: 30 }}
                      onChange={(e) =>
                        setFieldValue("supportNumber", e.target.value)
                      }
                    />
                    <ErrorMessage
                      name={`supportNumber`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Box>

                  <InputLabel style={{ fontSize: 16, marginRight: 10 }}>
                    Country Code : {countryCode}
                  </InputLabel>
                </Grid>
                {/* City */}
                <Grid style={{ marginTop: 20, width: 275 }}>
                  <GlobalLocationView
                    location={""}
                    code={code}
                    placeholder={"City"}
                    onChange={(value) => {
                      if (value) {
                        setCity(value.value.structured_formatting.main_text);
                        setFieldValue(
                          "city",
                          value.value.structured_formatting.main_text
                        );
                      }
                    }}
                  />
                  <ErrorMessage
                    name={`city`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid></Grid>
                {/* Airport */}
                <Grid style={{ marginBottom: 20 }}>
                  <GlobalLocationView
                    location={""}
                    placeholder={"Airport"}
                    code={code}
                    onChange={(value) => {
                      if (value) {
                        geocodeByAddress(value.label)
                          .then((results) => getLatLng(results[0]))
                          .then(({ lat, lng }) => {
                            setFieldValue("lat", lat);
                            setFieldValue("lng", lng);
                          });
                      }
                    }}
                  />
                  <ErrorMessage
                    name={`lat`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Radius */}
                <Grid
                  style={{
                    marginBottom: 20,
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                  }}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Input
                      type="number"
                      name="redius"
                      label="Radius"
                      size="small"
                      fullWidth
                      style={{ marginTop: 0, marginRight: 10, width: 100 }}
                      onChange={(e) => {
                        setFieldValue("redius", e.target.value);
                      }}
                    />
                    <InputLabel style={{ fontSize: 16 }}>Km</InputLabel>
                  </Box>
                  <ErrorMessage
                    name={`redius`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box marginRight={"12px"}>
                    <MOButton
                      title="Cancel"
                      type="secondary"
                      disabled={isSubmitting}
                      onClick={onClose}
                      icon={false}
                    />
                  </Box>
                  <MOButton
                    submitType="submit"
                    title="Add Network"
                    disabled={isSubmitting}
                  />
                </Grid>
              </Form>
            )}
          </Formik>
          {/* Formik Form handling end */}
        </div>
      </Dialog>
      {error && (
        <ErrorSnack
          reset={() => setError(false)}
          errorMsg={
            "Network Name is duplicated, Please make Network Name unique"
          }
        />
      )}
      {success && (
        <ErrorSnack
          reset={() => setSuccess(false)}
          success
          errorMsg={"Network created"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({
  networks: state.networks.response.data,
});

const mapDispatch = {
  createNetwork: ThunksNetworks.createNetworks,
  getAllNetworks: ThunksNetworks.Networks,
};

export default connect(mapState, mapDispatch)(CreateNetworkModalForm);
