import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Box, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
// import { AddCustomer } from "../../../redux/actions/local/customers";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import Local from "../../../redux/actions/local";

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface UpdateCustomerProps {
    updateCustomer: (body: any) => any;
    sendEmailCustomer: (id:String,email:String) => any;
  status: any;
  failure: any;
  success: any;
  reset: () => any;
  modalShow:any,
  setModalShow:()=>any
  handleClose:()=>any
  setDate:any
}

const UpdateCustomer: React.FC<UpdateCustomerProps> = ({
updateCustomer,
sendEmailCustomer,
  status,
  failure,
  success,
  reset,
  modalShow=false, 
 setModalShow=()=>{},
 handleClose=()=>{},
 setDate
}) => {
  const initailUser = {
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyAddress: "",
    companyPostCode: "",
    companyCity: "",
    companyRegistrationNumber: "",
    companyVATNumber: "",
    bankAccountNumber: "",
    bankAccountHolderName: "",
  }
  const classes = useStyles();
//   const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState<any>(initailUser);
  const [error, setError] = useState<any>({
    email: false,
    companyName: false,
    companyAddress: false,
    companyPostCode: false,
    companyCity: false,
    companyRegistrationNumber: false,
    companyVATNumber: false,
    bankAccountNumber: false,
    bankAccountHolderName: false,
    vatPercentage:false,
  });
const [emailSendStatus,setEmailSendStatus]=useState(false)

useEffect(()=>{
    
    if(setDate){
    setUser({
        customerId:setDate?.id,
        email: setDate?.email || "",
        firstName: setDate?.firstName || "",
        lastName: setDate?.lastName || "",
        companyName: setDate?.companyName || "",
        companyAddress: setDate?.companyAddress || "",
        companyPostCode: setDate?.companyPostCode || "",
        companyCity: setDate?.companyCity || "",
        companyRegistrationNumber: setDate?.companyRegistrationNumber || "",
        companyVATNumber: setDate?.companyVATNumber || "",
        bankAccountNumber: setDate?.bankAccountNumber || "",
        bankAccountHolderName: setDate?.bankAccountHolderName || "",
        vatPercentage: setDate?.vatPercentage?.toString() || ""
    })
    }
    
},[setDate])


  useEffect(() => {
    if (user.bankAccountHolderName !== "") {
      setError({ ...error, bankAccountHolderName: false });
    } else {
      setError({ ...error, bankAccountHolderName: true });
    }
  }, [user.bankAccountHolderName]);

  useEffect(() => {
    if (user.bankAccountNumber !== "") {
      setError({ ...error, bankAccountNumber: false });
    } else {
      setError({ ...error, bankAccountNumber: true });
    }
  }, [user.bankAccountNumber]);

  useEffect(() => {
    if (user.companyVATNumber !== "") {
      setError({ ...error, companyVATNumber: false });
    } else {
      setError({ ...error, companyVATNumber: true });
    }
  }, [user.companyVATNumber]);

  useEffect(() => {
    if (user.vatPercentage !== "") {
      setError({ ...error, vatPercentage: false });
    } else {
      setError({ ...error, vatPercentage: true });
    }
  }, [user.vatPercentage]);

  useEffect(() => {
    if (user.companyRegistrationNumber !== "") {
      setError({ ...error, companyRegistrationNumber: false });
    } else {
      setError({ ...error, companyRegistrationNumber: true });
    }
  }, [user.companyRegistrationNumber]);

  useEffect(() => {
    if (user.companyCity !== "") {
      setError({ ...error, companyCity: false });
    } else {
      setError({ ...error, companyCity: true });
    }
  }, [user.companyCity]);

  useEffect(() => {
    if (user.companyPostCode !== "") {
      setError({ ...error, companyPostCode: false });
    } else {
      setError({ ...error, companyPostCode: true });
    }
  }, [user.companyPostCode]);

  useEffect(() => {
    if (user.companyAddress !== "") {
      setError({ ...error, companyAddress: false });
    } else {
      setError({ ...error, companyAddress: true });
    }
  }, [user.companyAddress]);

  useEffect(() => {
    if (user.companyName !== "") {
      setError({ ...error, companyName: false });
    } else {
      setError({ ...error, companyName: true });
    }
  }, [user.companyName]);


  useEffect(() => {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (rgx.test(user.email) && user.email !== "") {
      setError({ ...error, email: false });
    } else {
      setError({ ...error, email: true });
    }
  }, [user.email]);
  
  const handleChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const sendCustomerEmailSubmit=async()=>{
    if(error.email){
        alert("Please Enter a valid email")
    }else if(!setDate?.id){
        alert("Customer Id missing.")
    }
    else if(setDate?.id || user.email){
        const result=await sendEmailCustomer(setDate?.id,user.email)
        if(!result){
            setEmailSendStatus(false)
        }else{
            setEmailSendStatus(true)
        }
       
        
    }else{
        alert("There is something is wrong. Please try Again")
    }

  }

  useEffect(()=>{
    if(success?.status){
      handleClose()
      setUser(initailUser);
    }
  },[success])

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalShow}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Update Customer
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Input
              label="Customer Email "
              value={user.email}
              required
              autoFocus
              type="email"
              error={error.email}
              size="small"
              name="email"
              className={classes.textfield_upper}
              onChange={(e: any) => handleChange(e)}
            />
          </Grid>
          <Box marginLeft={1}>
            <Typography
            onClick={()=>{sendCustomerEmailSubmit()}}
              style={{fontSize:14,fontWeight:600,color:"#170F49",cursor:"pointer",textDecoration:"underline"}}>Resend email</Typography>
            <Typography 
                style={{fontSize:12,fontWeight:400,color:"#6F6C90",marginTop:4}}
                >
                    Note: Use the 'Resend Email' feature when you need to create a new password for a client user account.</Typography>
          </Box>
          <Grid>
            <Input
              label="First Name"
              value={user.firstName}
              required
              type="text"
              // error={error.firstName}
              size="small"
              name="firstName"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Last Name"
              value={user.lastName}
              required
              type="text"
              // error={error.lastName}
              size="small"
              name="lastName"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company Name"
              value={user.companyName}
              required
              type="text"
              error={error.companyName}
              size="small"
              name="companyName"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company Address"
              value={user.companyAddress}
              required
              type="text"
              error={error.companyAddress}
              size="small"
              name="companyAddress"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company Postal Code"
              value={user.companyPostCode}
              required
              type="text"
              error={error.companyPostCode}
              size="small"
              name="companyPostCode"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company City"
              value={user.companyCity}
              required
              type="text"
              error={error.companyCity}
              size="small"
              name="companyCity"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company Registration Number"
              value={user.companyRegistrationNumber}
              required
              type="text"
              error={error.companyRegistrationNumber}
              size="small"
              name="companyRegistrationNumber"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Company VAT Number"
              value={user.companyVATNumber}
              required
              type="text"
              error={error.companyVATNumber}
              size="small"
              name="companyVATNumber"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Bank Account Number"
              value={user.bankAccountNumber}
              required
              type="text"
              error={error.bankAccountNumber}
              size="small"
              name="bankAccountNumber"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Bank Account Holder Name"
              value={user.bankAccountHolderName}
              required
              type="text"
              error={error.bankAccountHolderName}
              size="small"
              name="bankAccountHolderName"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="VAT Percentage"
              value={user.vatPercentage}
              required
              type="text"
              error={error.vatPercentage}
              size="small"
              name="vatPercentage"
              className={classes.textfield_upper}
              onChange={(e: any) =>handleChange(e)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              borderRadius: 8,
              backgroundColor: "#2AABD5",
              color: "#FFFFFF",
              height: 40,
            }}
            disabled={
              error.email ||
              error.companyName ||
              error.companyAddress ||
              error.companyPostCode ||
              error.companyCity ||
              error.companyRegistrationNumber ||
              error.companyVATNumber ||
              error.bankAccountNumber || 
              error.bankAccountHolderName ||
              error.vatPercentage
            }
            // disabled={brandName.length < 2}
            onClick={() => {
              let hasError = 0; 
              let getheringError: any = {};
              for (let key in user) {
                if (user[key] !== "") {
                  getheringError[key]= false;
                } else {
                  if(key == "firstName" || key == "lastName") {}
                  else{
                    getheringError[key]= true;
                    hasError = 1;
                  }
                }
              }

              setError(getheringError)

              if (!hasError) {
                updateCustomer(user);
                // handleClose();
                // setUser(initailUser);
              } else {
                alert("Please fill all the required fields.")
              }
              
            }}
            variant="contained"
          >
            Update 
          </Button>
        </DialogActions>
      </Dialog>
      {failure.status && (
        <ErrorSnack reset={() => reset()} errorMsg={failure.message} />
      )}
      {success.status && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Customer Update Successfully"}
        />
      )}
      { emailSendStatus && (
        <ErrorSnack
          reset={() => setEmailSendStatus(false)}
          success
          errorMsg={"The email has been successfully sent to the customer"}
        />
      )}
    </div>
  );
};
const mapState = (state: any) => ({
  status: state,
  failure: state.addCustomer.error,
  success: state.addCustomer.response,
});

const mapDispatch = {
    updateCustomer: ThunksCustomers.updateCustomer,
    sendEmailCustomer: ThunksCustomers.sendEmailCustomer,
    reset: Local.AddCustomer.api.reset,
};
export default connect(mapState, mapDispatch)(UpdateCustomer);
