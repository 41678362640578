import Local from "../../local";

export type ThunksNetworksListing = {
  Networks: () => any;
//   updatePassengers: (id: string, obj: any) => any;
//   deletePassenger: (id: string) => any;
  createNetworks: (obj: any) => any;
  deleteNetwork: (id : string) => any;
  getDriversForNetwork: (obj: any) => any;
  getDispatchersForNetwork: (obj: any) => any;
  getAllForNetwork: (obj: any) => any;
  getNetworkForOrganization: (obj: any) => any;
  getNetworkById: (id: string) => any;
  getNetworkByNetworkId: (id: string) => any;
};
const ThunksNetworks: ThunksNetworksListing = {
    Networks: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
      dispatch(Local.Networks.api.request());
    return api
      .getNetworks()
      .then((response: any) => {
        dispatch(Local.Networks.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Passengers.api.error("Request Failed", data.msg));
      });
  },
  createNetworks: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      console.log("this is user token", token);
      console.log(obj)
      const api = mobioApi(token);
        dispatch(Local.Networks.api.request());
      return api
        .createNetworks(obj)
        .then((response: any) => {
          dispatch(Local.Networks.api.response(response.data));
          return { state: true};
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          return {
            state: false,
            msg: data.msg
          }
        }); 
    },
    deleteNetwork: (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
        dispatch(Local.Networks.api.request());
      return api
        .deleteNetwork(id)
        .then((response: any) => {
          dispatch(Local.Networks.api.response(response.data));
          return true;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          dispatch(Local.Networks.api.error("Request Failed", data.msg));
        }); 
    },
    getDriversForNetwork: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getDriversForNetwork(obj)
        .then((response: any) => {
          return response.data.number
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
    getDispatchersForNetwork: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getDispatchersForNetwork(obj)
        .then((response: any) => {
          return response.data.number
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
    getAllForNetwork: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getAllForNetwork(obj)
        .then((response: any) => {
          return response.data
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
    getNetworkById: (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getNetworkById(id)
        .then((response: any) => {
          return response.data
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
    getNetworkForOrganization: (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getNetworkForOrganization(id)
        .then((response: any) => {
          return response.data
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
    getNetworkByNetworkId: (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getNetworkByNetworkId(id)
        .then((response: any) => {
          return response.data
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg)
        }); 
    },
};

export default ThunksNetworks;