import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { SearchOutlined } from "@material-ui/icons";
import MainListItems from "./MenuList";
import { connect } from "react-redux";
// import Charts from "./Charts";

import Mobio_Logo from "../../../assets/icons/Mobio_Logo.png";
import Mobio_Logo_V2 from "../../../assets/icons/update_logo.png";
import { Box, InputBase, alpha, styled } from "@material-ui/core";
import UserAvatar from "../../../components/commons/UserAvatar";
import { BellOutline, SearchOutline } from "../../../assets/icons";
import { useLocation, useNavigate } from "react-router";
import ThunksBooking from "../../../redux/actions/thunks/bookings";

// import NameSearch from "./NameSearch";
export interface Props {
  // non-style props
  label: any;
  path: any;
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// const drawerWidth = 280;
const drawerWidth = 262;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: "#FFF",
    backgroundColor: "#F7F7FB",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    boxShadow: "none",
    border: "none",
    // backgroundColor: "#FFF",
    paddingTop: "16px",
    backgroundColor: "#F7F7FB",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "#4B4B4B",
    fontSize: 16,
    fontWeight: "bold",
  },
  titlePrap: {
    flexGrow: 1,
    color: "#7C8DB5",
    fontSize: 16,
    // fontWeight: "bold",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: 24,
    paddingRight: 24
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    // height: 45,
    // width: 100,
    height: 130,
    width: 148,
    // marginTop: 35,
  },
  date: {
    border: 2,
  },
  select: {
    // backgroundColor: "red",
  },

}));





const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderBottom: '1px solid #4b4b4b',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
interface headerDetails {
  title: String;
  detail: String
}

function Dashboard({ user, children }: any) {
  const classes = useStyles();
  const [age, setAge] = React.useState(2020);
  const [open, setOpen] = React.useState(true);
  const [openSearch, setOpenSearch] = React.useState(false)
  const [headerDetail, setHeaderDetail] = React.useState<headerDetails>({
    title: '',
    detail: ''
  })
  const { pathname, search } = useLocation();

  const handleHeaderDetail = (title: String = "", detail: String = "") => {
    setHeaderDetail({
      title: title,
      detail: detail
    })
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const navigate = useNavigate();
  const handleSearching = (searchingValues: any) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('search', searchingValues);
    if (pathname.endsWith("-bookings")) {
      navigate(`${pathname}?${newSearchParams.toString()}`);
    } else if (pathname.endsWith("customer-users")) {
      navigate(`${pathname}?${newSearchParams.toString()}`);
    } else if (pathname.endsWith("manage-passengers")) {
      navigate(`${pathname}?${newSearchParams.toString()}`);
    } else if (pathname.endsWith("customer-invoice")) {
      navigate(`${pathname}?${newSearchParams.toString()}`);
    }

  }

  useEffect(() => {

    setHeaderDetail({
      title: "Booking",
      detail: "Here is the information about all your orders "
    })
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Box>
            <Typography
              component="h1"
              variant="h6"
              color="primary"
              noWrap
              className={classes.title}
            >
              {headerDetail?.title}
            </Typography>
            <Typography
              component="p"
              variant="body1"
              // color="primary"
              noWrap
              className={classes.titlePrap}
            >
              {headerDetail?.detail}
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Box sx={{ marginRight: "32px" }} style={{ display: "flex" }}>

              <div>
                {openSearch &&
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => {
                      handleSearching(e.target.value)
                    }}
                  />}
              </div>
              <IconButton color="primary">
                <SearchOutline onClick={() => { setOpenSearch(!openSearch) }} />
              </IconButton>
              <IconButton color="primary">
                <Badge color="primary">
                  <BellOutline />
                </Badge>
              </IconButton>
            </Box>
            <UserAvatar user={{
              fullName: user.firstName ? `${user?.firstName} ${user?.lastName}` : user.fullName ? user.fullName : user.displayName ? user.displayName : "NULL",
              role: `${user?.role} Profile`,
              imageUrl: user?.imageUrl && user?.imageUrl
            }} />
          </Box>
          {/* <NameSearch /> */}
        </Toolbar>
        {/* <Divider /> */}
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div style={{ marginRight: "9px", marginTop: "7px", borderRight: "1px solid #E6EDFF", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={Mobio_Logo} className={classes.logo} /> */}
            <img src={Mobio_Logo_V2} className={classes.logo} />
          </div>

          <List style={{ paddingLeft: "3px" }} >
            <MainListItems handleHeaderDetail={handleHeaderDetail} />
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

// const mapState = () => ({
// });

// const mapDispatch = {

//   getBookings: ThunksBooking.CustomerBookings,

// };

// export default connect(mapState, mapDispatch)(Dashboard);

export default Dashboard;