import GooglePlacesAutocomplete from "react-google-places-autocomplete";
function GlobalLocationView({ location, onChange, placeholder, code }) {

  function getTypes() {
    if (placeholder === "City") {
      return ["(cities)"]; // For city search
    } else if (placeholder === "Airport") {
      return ["airport"]; // For airport search
    }
    return [];
  };
  function getObj() {
    if (typeof location.name == "object" || location.name == "") {
      console.log(location.name);
      return location.name;
    }
    if (typeof location.name == "string") {
      let newLocation = { label: location.name, value: {} };
      return newLocation;
    }
  }
  return (
    <GooglePlacesAutocomplete
      apiKey= {process.env.REACT_APP_GOOGLE_MAP_API_KEY}
      debounce={200}
      autocompletionRequest={
        code != "" && {
          types: getTypes(),
          componentRestrictions: {
            country: [code],
          },
        }
      }
      selectProps={{
        value: getObj(),
        onChange: onChange,
        placeholder: placeholder,

        styles: {
          container: () => ({
            marginTop: 14,
          }),
          input: (provided) => ({
            ...provided,
            color: "grey",
          }),
          option: (provided) => ({
            ...provided,
            color: "grey",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "grey",
          }),
          menu: (provided) => ({
            // ...provided,
            backgroundColor: "FFFFF",
          }),
        },
      }}
    />
  );
}

export default GlobalLocationView;