import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Grid, Modal, Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import DispatcherAssign from "./DispatcherAssign";
import mobioApi from "../../../services";
import { useSearchParams } from 'react-router-dom'

function Dispatchers({ getDispatchers, response, status, error, dispatchers, token }) {
  const [dispatcherList, setDispatcherList] = useState([])
  const [selected, setSelected] = useState([])
  const [success, setSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsValue = searchParams.get("integration");
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getIntegrationDispatcher = async () => {
    await getDispatchers(null, null, searchParamsValue);
    const api = mobioApi(token);
    api.getIntegrationDispatchers(searchParamsValue == 'booking.com' ? "Booking.com" : "WorldTransfer.com").then((res) => {
      setDispatcherList(res.data)
      setloading(false)
    });
  }
  useEffect(() => {
    getIntegrationDispatcher()
  }, []);

  useEffect(() => {
    if (loading) {
      getIntegrationDispatcher()
    }
  }, [loading]);

  const deleteSelected = () => {
    if (selected.length == 0) alert("Please select dispatchers from list to remove.")
    else {
      const api = mobioApi(token);
      api
        .deleteIntegrationDispatchers({ dispatchers: selected, type: searchParamsValue })
        .then((res) => {
          setloading(true);
          setSuccess(true)
          setSelected([])
        })
        .catch((err) => alert("error"));
    }
  }

  const columns = [
    { title: "Display Name", field: "displayName", editable: "never" },
    { title: "Full Name", field: "fullName", editable: "never" },
  ];

  return (
    <>


      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          marginRight: 10
        }}
        onClick={() => handleOpen()}
        variant="contained"

      >
        Add Dispatcher
      </Button>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,

        }}
        onClick={() => deleteSelected()}
        variant="contained"

      >
        Delete
      </Button>

      <MaterialTable

        icons={tableIcons}
        isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={dispatcherList}
        options={{
          actionsColumnIndex: -1,
          selection: true,
        }}
        onSelectionChange={(rows) => {
          setSelected(rows)
        }
        }
        title="Dispatchers"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 5,
          p: 4,
        }}>
          <Grid container>
            <DispatcherAssign setOpen={setOpen} setloading={setloading} type={searchParamsValue} />
          </Grid>
        </Box>
      </Modal>
      {success && (
        <ErrorSnack
          success
          reset={() => setSuccess(false)}
          errorMsg={"Dispatchers are removed Successfully."}
        />
      )}
      {error && <ErrorSnack errorMsg={error} />}
    </>
  );
}

const mapState = (state) => ({
  token: state.userInfo.token,
  dispatchers: state.customerDispatcher.response.data,
});

const mapDispatch = {
  getDispatchers: ThunksCustomers.getCustomerDispatchers,
};

export default connect(mapState, mapDispatch)(Dispatchers);
