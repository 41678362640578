import React, { useEffect, useState } from "react";
import ThunksTaxiCompanies from "../../../redux/actions/thunks/company-list";
import { connect } from "react-redux";
import ThunksNetworks from "../../../redux/actions/thunks/networks";

// Generate Order Data

function NetworkName({ getNetworkForOrganization, rowData }) {
  const [networks, setNetworks] = useState("");
  useEffect(() => {
    getCompanyDetail(rowData);
  }, []);
  const getCompanyDetail = async(rowData) => {
    const networkNames = await getNetworkForOrganization(rowData.id)
    setNetworks(networkNames);
  }
  return <p>{networks.toString()}</p>;
}


const mapDispatch = {
  getNetworkForOrganization: ThunksNetworks.getNetworkForOrganization
};

export default connect(null, mapDispatch)(NetworkName);