import { useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  InputLabel

} from "@material-ui/core";
import Input from "../../../components/commons/Input";
import CountrySelect from "./CountrySelect";
import GlobalLocationView from "../../../components/commons/GlobalLocationField";
import ThunksNetworks from "../../../redux/actions/thunks/networks";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import ErrorSnack from "../../../components/commons/ErrorSnack";
const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    marginRight: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
}));

function CreateNetworkModal({
  open,
  onClose,
  createNetwork,
}) {
    const classes = useStyles();
    const [networkName, setNetworkName] = useState("");
    const [countryName, setCountryName] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");
    const [airportLat, setAirportLat] = useState("");
    const [airportLng, setAirportLng] = useState("");
    const [radius, setRadius] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [city, setCity] = useState(false);
    const [code, setCode] = useState("");
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <DialogTitle className={classes.heading} id="simple-dialog-title">
              Add Network
            </DialogTitle>
          </Grid>

          <Grid style={{ display:"flex", flexDirection:"row"}}>
            <Input
              label="Network Name"
              size="small"
              fullWidth
              style={{ marginTop: 0, marginRight: 10, width: 290}}
              onChange = {(e)=>{
                setNetworkName(e.target.value)
              }}
            />
            <CountrySelect setCountryName={setCountryName} setCountryCode={setCountryCode} setCode={setCode}/>
          </Grid>
          <Grid style={{ display:"flex", flexDirection:"row", marginTop:20, alignItems:"center"}}>
            <Input
              label="Whatsapp Number"
              size="small"
              fullWidth
              style={{ marginTop: 0, width: 275, marginRight:30}}
              onChange={(e)=>{
                setWhatsappNumber(e.target.value)
              }}
            />
            <InputLabel style={{ fontSize: 16, marginRight: 10  }}>
              Country Code : {countryCode}
            </InputLabel>

          </Grid>
          <Grid style={{ marginTop: 20, width: 275}}>
            <GlobalLocationView
                location={""}
                code={code}
                placeholder={"City"}
                onChange={(value) => {
                  // console.log(value.value.structured_formatting.main_text)
                  if(value){
                    setCity(value.value.structured_formatting.main_text)
                  }
                }}
              />
          </Grid>
          <Grid>
          </Grid>
          <Grid style={{ marginBottom : 20}}>
            <GlobalLocationView
                location={""} 
                placeholder={"Airport"}
                code={code}
                onChange={(value) => {
                  if (value) {
                    geocodeByAddress(value.label)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) =>{
                        setAirportLat(lat);
                        setAirportLng(lng)
                      }
                    );
                  }
                }}
              />
          </Grid>
          <Grid style={{ marginBottom: 20, display:"flex", flexDirection:"row", alignItems:"center"}}>
            <Input
                type= "number"
                label="Radius"
                size="small"
                fullWidth
                style={{ marginTop: 0, marginRight: 10, width: 100}}
                onChange={(e)=>{setRadius(e.target.value)}}
            />
            <InputLabel style={{ fontSize: 16}}>
              Km
            </InputLabel>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              // disabled
              style={{
                borderRadius: 6,
                backgroundColor: "primary",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              onClick={
                async ()=>{
                  if(networkName != "" && countryName != "" && countryCode != "" && whatsappNumber != "" && airportLat != "" && airportLng != "" && radius != "" && city != ""){
                      let networkData = {
                        name: networkName,
                        country: countryName,
                        supportNumber: whatsappNumber,
                        countryCode: countryCode,
                        documentImage: "",
                        lat: airportLat,
                        lng: airportLng,
                        redius: radius,
                        city: city
                      }
                      let result = await createNetwork(networkData);
                      if(result.state == true){
                        onClose();
                        setSuccess(true)
                      }else{
                        setError(true)

                      }
                  }
                }
              }   
            >
              Create
            </Button>
          </Grid>
        </div>
      </Dialog>
      {error && (
        <ErrorSnack reset={() => setError(false)} errorMsg={"Network Name is duplicated, Please make Network Name unique"} />
      )}
      {success && (
        <ErrorSnack
          reset={() => setSuccess(false)}
          success
          errorMsg={"Network created"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({
  networks: state.networks.response.data
});

const mapDispatch = {
 createNetwork: ThunksNetworks.createNetworks,
 getAllNetworks: ThunksNetworks.Networks
};

export default connect(mapState, mapDispatch)(CreateNetworkModal);