import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/svgs/landing-mobio-logo.svg";
import { ReactComponent as PCMock } from "../../../assets/svgs/landing-mobio-pc-mock.svg";
import "./landing.css";

const landing = () => {
  return (
    <div className="landing-box">
      <Logo className="landing-logo" />
      <div className="landing-content">
        <div className="landing-textbox">
          <h2 className="landing-main-heading">
            Play A Key Role In Delivering Exceptional Service And Driving
            Operational Excellence
          </h2>
          <p className="landing-description">
            Be at the heart of our operations, ensuring efficient and timely
            coordination between drivers and clients. Optimize routes, manage
            schedules, and handle real-time adjustments to keep everything
            running smoothly
          </p>
        </div>
        <Link className="landing-button" to={"/sign-up"}>
          Begin you journey
        </Link>
      </div>
      <PCMock className="landing-laptop-img" />
    </div>
  );
};

export default landing;
