import { Box, Chip, FormControl, Grid, Input, InputLabel, MenuItem, NativeSelect, Select } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Accordion from "@material-ui/core/Accordion";
import CheckCircle from "@material-ui/icons/CheckCircle";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import moment from "moment";
import { floor } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import * as momentTimeZone from 'moment-timezone';
import ThunkSpreadSheet from "../../../redux/actions/thunks/spread-sheet";
import Union from "../../../assets/icons/Union.png";
import Destination from "../../../assets/icons/Destination.png";
import person from "../../../assets/icons/person.png";
import { cartypes } from "./VehicleTypes";
import UpdateBooking from "./UpdateBooking";
import DetailViewDots from "./CustomerBookingList/DetailDot";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import CreateBookings from "./CreateBooking";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import ThunksUsers from "../../../redux/actions/thunks/users";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import { EventTextDetails } from "../../../enum";
import vehicles from "../../../assets/VehicleSvgs";
import { DifCars } from "../../../enum";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import DisplayName from "./DisplayName";
import { write, utils } from 'xlsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { showGoogleMap } from "../../../helpers";
import Local from "../../../redux/actions/local";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #4b4b4b",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
  },
  ul: {
    backgroundColor: "inherit",
  },
  root_Tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  //accordian
  root_accordian: {
    width: "100%",
    backgroundColor: "red",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  counts: {
    border: "1px solid black",
    padding: 10,
  },
  createSheetButton: {
    borderRadius: 8,
    backgroundColor: "rgb(101 200 230)",
    color: "#FFFFF",
    height: 25,
    textTransform: "none",
    marginLeft: 10,
  },
  viewSheetButton: {
    borderRadius: 8,
    backgroundColor: "rgb(32 179 50 / 62%)",
    color: "#FFFFF",
    height: 25,
    textTransform: "none",
    marginLeft: 10,
  },
  downloadButton: {
    borderRadius: 8,
    backgroundColor: "rgb(161 223 242)",
    color: "#FFFFF",
    height: 25,
    textTransform: "none",
    marginLeft: 10,
  },
  cancelBooking: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    opacity: "0.4",
    background: "red"
  }
}));

function BookingList({
  getBookings,
  data,
  loading,
  error,
  role,
  GetDriverEventsBybooking,
  GetBookingdriver,
  GetAssignedPaginated,
  AuthURL,
  CreateSheet,
  sheets,
  getDisplayNameByClientId,
  getDisplayNameByUserId,
  cancelPendingBooking,
  todayBookingState,
  getTotalAndAssignedRides,
  sheetError,
  sheetSuccess,
  resetAll
}) {
  const classes = useStyles();
  const [type, setType] = useState("planning");
  const [expand, setExpand] = useState("");
  const [events, setEvents] = useState([]);
  const [driver, setDriver] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(Number(localStorage.getItem("PageSize")) || 10);
  const [total, setTotal] = useState(null);
  const [count, setCount] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [errorDetail, setErrorDetail] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [successStatus, setSuccessStatus] = useState({ status: false, msg: '' });
  const [errorStatus, setErrorStatus] = useState({ status: false, msg: '' });
  const pageSizeOptions = [5, 10, 20, 30, 50, 100];

  useEffect(() => {
    if (params.get("code")) {
      createSheetApi();
    }
  }, [params.get("code")]);

  const handleEyeButton = async (pickup, dest, driverId) => {
    const originalLat = pickup.coordinates[0];
    const originalLng = pickup.coordinates[1];
    const destinationLat = dest.coordinates[0];
    const destinationLng = dest.coordinates[1];
    const origin = encodeURIComponent(originalLat + "," + originalLng);
    const destination = encodeURIComponent(destinationLat + "," + destinationLng);
    const { lat, lng } = await getDisplayNameByUserId(driverId);
    const waypoints = encodeURIComponent(lat + "," + lng);
    const link = `https://www.google.com/maps/dir/?api=1&origin=${origin}&waypoints=${waypoints}&destination=${destination}`;
    window.open(link, "_blank");
  }


  useEffect(async () => {
    if (searchText !== "") {
      if (type == "history") {
        const count = await getBookings(type, page, limit, searchText);
        countTotalPage(count);
      } else if (type == "assigned") {
        const count = await GetAssignedPaginated(type, page, limit, searchText);
        countTotalPage(count);
      } else {
        const count = await getBookings(type, page, limit, searchText);
        countTotalPage(count);
        await getTotalAndAssignedRides()
      }
    }
  }, [searchText]);

  const showGooglePlace = (lat, lng) => {
    const origin = encodeURIComponent(lat + "," + lng);
    const link = `https://www.google.com/maps/place/${origin}`;
    window.open(link, "_blank");
  }

  const toggleAcordion = (newId) => {
    setExpand((id) => (id == newId ? "" : newId));
  };

  const getDriverInfo = async (id) => {
    const result = await GetBookingdriver(id);
    setDriver(result);
  };

  const getEvents = async (id) => {
    const result = await GetDriverEventsBybooking(id);
    setEvents(result);
  };

  useEffect(async () => {
    if (type == "history") {
      const count = await getBookings(type, page, limit, searchText);
      countTotalPage(count);
    } else if (type == "assigned") {
      const count = await GetAssignedPaginated(type, page, limit, searchText);
      countTotalPage(count);
    } else {
      const count = await getBookings(type, page, limit, searchText);
      countTotalPage(count);

      await getTotalAndAssignedRides()
    }
  }, [type]);

  const countTotalPage = (count) => {
    setCount(count);
    if (parseInt(count) % parseInt(limit) !== 0) {
      setTotal(floor(parseInt(count) / parseInt(limit)) + 1);
    } else {
      setTotal(parseInt(count) / parseInt(limit));
    }
  };

  useEffect(async () => {
  if (loading) {
  if (type == "history") {
  const count = await getBookings(type, page, limit, searchText);
  countTotalPage(count);
  } else if (type == "assigned") {
  const count = await GetAssignedPaginated(type, page, limit, searchText);
  countTotalPage(count);
  } else {
  const count = await getBookings(type, page, limit, searchText);
  countTotalPage(count);
  await getTotalAndAssignedRides();
  }
    }
  }, [loading]);


  // after error message

  useEffect(async () => {
    if (error?.status) {
      setErrorDetail(error)
    }
    setTimeout(() => {
      setErrorDetail("")
    }, 5000);

  }, [error]);

  const download = (src) => {
    console.log(src);
    var element = document.createElement("a");
    var file = new Blob([src], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };

  function SingleSource({ src, title }) {
    const [zoomed, setZoomed] = useState(false);

    return (
      <>
        <div style={{ marginRight: 16 }}>
          <span>{title}</span>

          <img
            style={{ width: "150px", height: "150px" }}
            src={src}
          // onClick={() => download(src)}
          />
        </div>
      </>
    );
  }
  const onpageChange = async (selectedPage, selectedLimit) => {
    console.log("selecte dpage", selectedPage, limit)
    if (type == "history") {
      const count = await getBookings(type, selectedPage, selectedLimit || limit, searchText);
      countTotalPage(count);
    } else if (type == "assigned") {
      const count = await GetAssignedPaginated(type, page, selectedLimit || limit, searchText);
      countTotalPage(count);
    } else if (type == 'planning') {
      const count = await getBookings(type, selectedPage, selectedLimit || limit, searchText);
      countTotalPage(count);

    }
  };

  const returnImage = (type) => {
    switch (type) {
      case "CASH":
        return <LocalAtmIcon />;
      case "CARD":
        return <CreditCardIcon />;
      case "ACCOUNT":
        return <AccountBalanceWalletOutlinedIcon />;
    }
  };

  const handleCreate = async (date, longDate) => {
    const { authUrl } = await AuthURL({ date, longDate });
    window.location.href = authUrl;
  };

  const createSheetApi = async () => {
    try {
      const resp = await CreateSheet({
        code: params.get("code"),
        date: params.get("state")?.split('_')[0],
        longDate: params.get("state")?.split('_')[1],
      });

      if (resp) {
        navigate("/dashboard/bookings");
        await getBookings(type, "", "", searchText);
        if (resp.error) {
          setErrorStatus({ status: true, msg: resp.msg })
        } else {
          setSuccessStatus({ status: true, msg: resp.msg })
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleViewSheet = async (date) => {
    const matchingSheet = (await sheets.find((item) => item.date === date)) || {};
    const viewURL = `https://docs.google.com/spreadsheets/d/${matchingSheet.sheetId}/edit#gid=${matchingSheet.innerSheetId}`;
    window.open(viewURL, "_blank");
  };

  const existSheet = (date) => {
    const matchingSheet = sheets?.filter(
      (item) => item.date === date
    );
    return matchingSheet?.length > 0;
  };

  const handleDownload = async (item) => {
    const { data, date } = item;
    const bookings = await data.map(formatBooking);
    const workbook = utils.book_new();
    // Convert JSON data to worksheet
    const worksheet = utils.json_to_sheet(bookings);
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `booking_${date}.xlsx`;
    element.click();
  };

  function formatBooking(booking) {
    return {
      'BOOKING REFERENCE': booking.bookingReference ? booking.bookingReference : booking.refId,
      'PICKUP DATE AND TIME': new Date(booking.dateTime).toISOString().replace(/T/, ' ').replace(/\..+/, ''),
      'Flight NUMBER': booking.flightNumber,
      'PASSENGER NAME': booking.guestName,
      'CUSTOMER CONTACT NUMBER': booking.guestPhoneNumber,
      'NO OF PASSENGER': booking.noOfPeople,
      'VEHICLE TYPE': booking.carType,
      'PICKUP ADDRESS': booking.pickup.name + '-' + booking.pickup.coordinates,
      'DROP OFF ADDRESS': booking.destination.name,
      'PASSANDGER COMMENTS': booking.notes,
      'RIDE END': booking.rideEnd,
      'DRIVER ID': booking.driverId,
      'CANCELLED': booking.cancelled,
    };
  }

  const handleBackground = (data) => {
    if (!data.cancelled) {
      if ((data.flightNumber != null || data.flightNumber != "") && data.landed_dif > 0 && data.flightLanded == true || (data.flightInfo != null && (data?.flightInfo?.status == "Arrived / Gate Arrival" || data?.flightInfo?.status == "Arrived / Delayed"))) {
        if (data.landed_dif >= 20) {
          if (data.landed_dif >= 60) {
            return { background: "#F4CCCC" }
          }
          else {
            return { background: "#FDE5CD" }
          }
        }
        else {
          return { background: "#D9EAD3" }
        }

      }
      else {
        return { background: "white" }
      }
    } else if (data.cancelled && !data.rideEnd) {
      return { background: "rgb(255,0,0,0.3)" }
    } else {
      return { background: "white" }
    }

  }
  async function handlePageChange(event) {
    setLimit(event.target.value)
    await localStorage.setItem("PageSize", event.target.value);
    setPage(1);
    onpageChange(1, event.target.value)
  }
    return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledToggleButtonGroup
          value={type}
          exclusive
          onChange={(e, value) => value !== null && setType(value)}
        >
          <StyledToggleButton disabled={loading} key={0} value={"planning"}>
            Planning
          </StyledToggleButton>
          <StyledToggleButton disabled={loading} key={1} value={"assigned"}>
            Assigned
          </StyledToggleButton>
          <StyledToggleButton disabled={loading} key={2} value={"history"}>
            History
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 20 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Search>
          </div>
          <div>
            <CreateBookings role={role} type={type} />
          </div>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        // alignItems="stretch"
        style={{ marginTop: 20 }}>
        {total !== null && (
          <>
            <NativeSelect
              value={limit}
              style={{ width: '7.5rem', fontSize: 14, marginRight: "1rem" }}
              defaultValue={limit}
              onChange={handlePageChange}
            >
              {pageSizeOptions.map((item) => (
                <option value={item} key={item}>Per Page - {item}</option>
              ))}
            </NativeSelect>
            <div>
              <StyledToggleButtonGroup
                style={{ background: "white" }}
                value={page}
                exclusive
              >
                {page > 1 && (
                  <StyledToggleButton
                    onClick={async () => {
                      setPage(page - 1);
                      await onpageChange(page - 1);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    <Typography
                      style={{ padding: "10 3 10 3" }}
                      component="h3"
                      align="center"
                    >
                      {" "}
                      Previous{" "}
                    </Typography>
                  </StyledToggleButton>
                )}
                {page !== 1 && (
                  <StyledToggleButton
                    onClick={async () => {
                      setPage(page - 1);
                      await onpageChange(page - 1);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    <Typography
                      style={{ padding: "10 3 10 3" }}
                      component="h3"
                      align="center"
                    >
                      {" "}
                      {page - 1}
                    </Typography>
                  </StyledToggleButton>
                )}
                <StyledToggleButton
                  style={{ background: "white", marginRight: 10 }}
                >
                  <Typography
                    style={{ padding: "10 3 10 3", color: "#2aabd5" }}
                    component="h3"
                    align="center"
                  >
                    {page}
                  </Typography>
                </StyledToggleButton>

                {total > page && (
                  <StyledToggleButton
                    style={{ marginRight: 10 }}
                    onClick={async () => {
                      setPage(page + 1);
                      await onpageChange(page + 1);
                    }}
                  >
                    <Typography
                      style={{ padding: "10 3 10 3" }}
                      component="h3"
                      align="center"
                    >
                      {page + 1}
                    </Typography>
                  </StyledToggleButton>
                )}
                {total > page && (
                  <StyledToggleButton
                    style={{ marginRight: 10 }}
                    onClick={async () => {
                      setPage(page + 1);
                      await onpageChange(page + 1);
                    }}
                  >
                    <Typography
                      style={{ padding: "10 3 10 3" }}
                      component="h3"
                      align="center"
                    >
                      Next
                    </Typography>
                  </StyledToggleButton>
                )}
              </StyledToggleButtonGroup>
              <div style={{ float: "right" }}>
                <ListSubheader style={{ marginTop: "-4px" }}>
                  <span style={{ fontWeight: "bold" }}>Total Count: </span>
                  <span>{count}</span>
                </ListSubheader>
              </div>
            </div>
          </>
        )}
      </Grid>
      <List
        className={classes.root}
        style={{ marginTop: "60px" }}
        subheader={<li />}
      >
        {!loading && data.length == 0 && (
          <Typography style={{ marginTop: 30 }} component="h3" align="center">
            {type && type.toUpperCase()} BOOKINGS ARE EMPTY
          </Typography>
        )}
        {data?.map((item, index) => {
          const indexOfToday = todayBookingState.length > 0 ? todayBookingState.findIndex(todays => todays.date === item.date) : -1
          return (
            <li key={index} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>
                  <span>{item?.date}</span>
                  <Button
                    className={classes.downloadButton}
                    variant="contained"
                    onClick={() => { handleDownload(item) }}
                  >
                    Download Sheet
                  </Button>
                  {
                    type === 'planning' &&
                    < span
                      style={{
                        background: "lightgrey",
                        marginLeft: '1rem',
                        padding: "0.2rem",
                        borderRadius: 3,
                        paddingRight: "0.3rem",
                        fontWeight: "bold",
                        color: "#000000"
                      }}>
                      {indexOfToday != -1 && `${todayBookingState[indexOfToday].remaining}/${todayBookingState[indexOfToday].remaining + todayBookingState[indexOfToday].completed}/`}
                      {indexOfToday != -1 && <span style={{ color: "red" }}>{todayBookingState[indexOfToday].cancelled}</span>}
                    </span>
                  }
                  {existSheet(item?.date) ? (
                    <>
                      <Button
                        className={classes.viewSheetButton}
                        variant="contained"
                        onClick={() => {
                          handleViewSheet(item.date);
                        }}
                      >
                        View Sheet
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={classes.createSheetButton}
                      variant="contained"
                      onClick={() => {
                        handleCreate(item.date, item.data[0].dateTime);
                      }}
                    >
                      Create Sheet
                    </Button>
                  )}
                </ListSubheader>
                {item?.data?.map((i, index) => (
                  <ListItem key={index}>
                    <div className={classes.root}>
                      <Accordion
                        expanded={i.id == expand ? true : false}
                        TransitionProps={{ unmountOnExit: true }}
                        style={handleBackground(i)}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            container
                            direction="row"
                          >
                            <Typography className={classes.heading} style={{ maxWidth: 251, paddingRight: 2, marginTop: "20px", marginLeft: "10px" }}>
                              {
                                type !== "planning" && <DetailViewDots type={type} data={i} />
                              }
                            </Typography>

                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              marginLeft: 15,
                              marginRight: 18,
                              marginTop: 10,
                            }}
                          >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                              <Typography style={
                                i?.flightInfo && i.flightInfo.arrival_delay !== 0 && i?.flightInfo?.estimated_in !== null ?
                                  { textDecoration: "line-through", marginRight: "10px", fontWeight: "600", fontSize: "18px" }
                                  :
                                  { fontWeight: "600", fontSize: "18px" }
                              }>
                                {moment(i?.flightTime || i?.dateTime).format("HH:mm")}
                              </Typography>
                              {i?.flightInfo != null && i?.flightInfo?.arrival_delay !== 0 && i?.flightInfo?.estimated_in !== null &&
                                <Typography style={
                                  i.flightInfo.arrival_delay > 0 ?
                                    { color: "red", fontSize: "12px", marginTop: "8px", fontWeight: "600" }
                                    :
                                    { color: "green", fontSize: "12px", marginTop: "8px", fontWeight: "600" }
                                }>
                                  {moment(i?.flightInfo?.estimated_in).format("HH:mm")}
                                </Typography>
                              }
                            </div>
                            <div>
                              <Typography style={{ fontWeight: 600 }}>
                                {i?.bookingReference
                                  ? i?.bookingReference
                                  : i.refId}
                              </Typography>
                            </div>
                          </Grid>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            IconButtonProps={{
                              onClick: async () => {
                                setEvents([]);
                                setDriver(null);
                                if (i.driverId) {
                                  await getDriverInfo(i.driverId);
                                }
                                await getEvents(i.id);
                                toggleAcordion(i.id);
                              },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            style={{ margin: 0, width: "100%" }}
                          >
                            <Grid xs={12} md={10} lg={10}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid container direction="row">
                                  <Typography className={classes.heading} onClick={() => { showGoogleMap(i.pickup.coordinates, i.destination.coordinates) }}>
                                    <img
                                      src={Union}
                                      style={{ marginRight: 10, marginTop: 6 }}
                                    />
                                    <span>{i?.pickup?.name}</span>
                                  </Typography>

                                  <Typography className={classes.heading} onClick={() => { showGoogleMap(i.pickup.coordinates, i.destination.coordinates) }}>
                                    <img
                                      src={Destination}
                                      style={{ marginRight: 10, marginTop: 6 }}
                                    />
                                    {i?.destination?.name}
                                  </Typography>
                                  <Typography className={classes.heading}>
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      style={{ marginLeft: 10 }}
                                    >
                                      {/* {returnImage(i.paymentType)} */}

                                      {/* <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          marginRight: 30,
                                          marginRight: 10,
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CP: €{i?.fare?.toFixed(2) || '0'}
                                      </p>
                                      <p
                                        style={{
                                          marginRight: 30,
                                          marginRight: 10,
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        DP: €{i?.finalPrice?.toFixed(2) || '0'}
                                      </p>
                                    </div> */}
                                      <img
                                        src={person}
                                        style={{ marginRight: 10 }}
                                      />
                                      <p style={{ marginRight: 30 }}>
                                        {i.noOfPeople}
                                      </p>
                                      {cartypes
                                        .filter((cars) => cars.value == i.carType)
                                        .map((carname) => (
                                          <div
                                            style={{
                                              width: DifCars[i?.carType]
                                                ? 100
                                                : 0,
                                              marginLeft: DifCars[i?.carType]
                                                ? -2
                                                : 0,
                                            }}
                                          >
                                            {vehicles(i.carType, "#4b4b4b", "50")}
                                          </div>
                                        ))}
                                    </Grid>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {type !== "history" && (
                              <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ cursor: "pointer", marginRight: "8px", marginTop: "8px" }}>
                                  {
                                    type == "assigned" && role == "Organization" && <a onClick={() => handleEyeButton(i.pickup, i.destination, i.driverId)}> <VisibilityIcon /> </a>
                                  }
                                </div>
                                <UpdateBooking data={i} />
                              </Grid>
                            )}
                          </AccordionSummary>
                        </Grid>
                        <AccordionDetails>
                          <Grid xs={12} md={10} lg={10}>
                            <Typography className={classes.heading}>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i.client && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Client:
                                    </h3>
                                    {i.client?.firstName} {i.client?.lastName}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i.guestName && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Guest Name:
                                    </h3>
                                    {i.guestName}
                                  </div>
                                )}
                                {i.guestPhoneNumber && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Guest Number:
                                    </h3>
                                    {i.guestPhoneNumber}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i?.dateTime && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Passenger Local Time:
                                    </h3>
                                    {momentTimeZone(i?.dateTime).tz(i?.timeZone).format("HH:mm")}
                                  </div>
                                )}
                                {i?.dateTime && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Time Left To Pickup:
                                    </h3>
                                    {moment.utc(i?.dateTime).fromNow()}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i.flightNumber && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Flight Number:
                                    </h3>
                                    {i.flightNumber}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i.flightLandTime && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Flight Landing Time:
                                    </h3>
                                    {moment(i?.flightLandTime || i?.flightTime || i?.dateTime).format("HH:mm")}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i?.dispatcher && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Dispatcher Name:
                                    </h3>
                                    {i?.dispatcher?.displayName}
                                  </div>
                                )}
                                {i?.driver && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Driver Name:
                                    </h3>
                                    {i?.driver?.displayName}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i?.dispatcher && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Dispatcher Number:
                                    </h3>
                                    {i?.dispatcher?.phoneNumber}
                                  </div>
                                )}
                                {i?.driver && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Driver Number:
                                    </h3>
                                    {i?.driver?.phoneNumber}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                <div style={{ width: "50%" }}>
                                  {i.nameCaller && (
                                    <>
                                      <h3 style={{ margin: 0, fontSize: "15px" }}>
                                        Name Caller:
                                      </h3>
                                      {i.nameCaller}
                                    </>
                                  )}
                                </div>
                                {driver?.car && driver?.car?.category && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Driver Service Level:
                                    </h3>
                                    {driver?.car && driver?.car?.category}
                                  </div>
                                )}
                              </div>
                              <div
                                style={{ display: "flex", flexDirection: "row" }}
                              >
                                {i.notes && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Notes
                                    </h3>
                                    <span>{i.notes}</span>
                                  </div>
                                )}
                              </div>
                              {
                                i.rideEnd == false && i.cancelled == true && <div
                                  style={{ display: "flex", flexDirection: "row" }}>
                                  {(i.cancelledByUser || i.cancelledByClient) && <DisplayName item={i} getDisplayNameByClientId={getDisplayNameByClientId} getDisplayNameByUserId={getDisplayNameByUserId} />}

                                  {i?.cancellationTime && <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Cancellation Time:
                                    </h3>
                                    {moment(i?.cancellationTime).format("HH:mm")}
                                  </div>}
                                </div>
                              }

                            </Typography>
                          </Grid>
                        </AccordionDetails>
                        {type == "history" && events.length > 0 && (
                          <>
                            <hr style={{ margin: 15 }} />
                            <AccordionDetails>
                              <div>
                                <div style={{ marginVertical: 10 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        marginRight: 10,
                                        marginTop: 4,
                                        marginBottom: 0,
                                      }}
                                    >
                                      Driver Tracking Events
                                    </p>
                                    <div
                                      style={{
                                        borderRadius: 8,
                                        padding: 7,
                                        backgroundColor: i.rideEnd
                                          ? "green"
                                          : "#E4E4E4",
                                      }}
                                    >
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          color: i.rideEnd
                                            ? i?.cancelled
                                              ? "#F46262"
                                              : "white"
                                            : "#4B4B4B",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Completed
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <>
                                    {events.length > 0 &&
                                      events.map((event) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 10,
                                            marginBottom: 10,
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginTop: 10,
                                              marginRight: 10,
                                              paddingLeft: 7,
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 4,
                                                marginTop: 16,
                                                backgroundColor: "black",
                                              }}
                                            ></div>
                                            <div style={{ marginLeft: -11 }}>
                                              <CheckCircle
                                                style={{
                                                  color: "green",
                                                  width: 20,
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => { showGooglePlace(event.lat, event.long) }}
                                          >
                                            <p style={{
                                              fontSize: 16,
                                              fontWeight: "bold",
                                              padding: 0,
                                              marginBottom: 5,
                                              marginTop: 8,
                                            }}
                                            >
                                              {EventTextDetails[event.eventName]}
                                            </p>

                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  color: "black",
                                                  padding: 0,
                                                  margin: 0,
                                                }}
                                              >
                                                {moment(event.createdAt).format(
                                                  "DD MMM YYYY HH:mm:ss"
                                                )}{" "}
                                              </p>
                                              <div
                                                style={{
                                                  width: 5,
                                                  height: 5,
                                                  backgroundColor: "black",
                                                  borderRadius: 8,
                                                  marginRight: 5,
                                                  marginLeft: 5,
                                                  marginTop: 0,
                                                }}
                                              ></div>
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  padding: 0,
                                                  margin: 0,
                                                }}
                                              >
                                                {event.lat}, {event.long}{" "}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </>
                                </div>
                              </div>
                            </AccordionDetails>
                          </>
                        )}
                        {i?.cancelled &&
                          i?.rideEnd &&
                          !!i?.cancellationReason && (
                            <>
                              <hr
                                style={{
                                  margin: 5,
                                  borderTop: "1px solid #4B4B4B",
                                }}
                              />
                              <AccordionDetails>
                                <Grid item xs={6}>
                                  {
                                    i?.cancellationReason?.reason &&
                                    <>
                                      <h4 > Reason of cancellation</h4>
                                      <div style={{ display: "flex" }}>
                                        {i?.cancellationReason?.reason}
                                      </div>
                                    </>
                                  }
                                  {
                                    i?.cancellationReason?.picture?.length > 0 &&
                                    <>
                                      <h4>No Show</h4>
                                      <div style={{ display: "flex" }}>
                                        {i?.cancellationReason?.picture?.length > 0 &&
                                          i?.cancellationReason?.picture?.map(
                                            (pic) => <SingleSource src={pic} />
                                          )}
                                      </div>
                                    </>
                                  }
                                </Grid>
                              </AccordionDetails>
                            </>
                          )}
                      </Accordion>
                    </div>
                    {
                      i.pendingCancellation == true && i.cancelled == false && <div style={{ position: "absolute", zIndex: "1", width: "100%", height: "100%", opacity: "0.6", background: "white", top: "0px", left: "0pd" }}>
                      </div>
                    }
                    {
                      i.pendingCancellation == true && i.cancelled == false && <Button style={{ position: "absolute", zIndex: "1", width: "fit-intent", height: "30px", opacity: "0.6", background: "white", bottom: "15px", right: "25px", color: "white", background: "red" }} onClick={() => { cancelPendingBooking(i.id) }}>Confirm Cancellation
                      </Button>
                    }
                  </ListItem>
                ))}
              </ul>
            </li>
          )
        }
        )}
      </List>
      {/* {error.status && <ErrorSnack errorMsg={error.message} />} */}
      {errorDetail.status && <ErrorSnack errorMsg={errorDetail.message} />}
      {successStatus.status && <ErrorSnack success errorMsg={successStatus.msg} />}
      {errorStatus.status && <ErrorSnack errorMsg={errorStatus.msg} />}
      {!!sheetError && <ErrorSnack errorMsg={sheetError} reset={resetAll} />}
      {!!sheetSuccess && <ErrorSnack success errorMsg={sheetSuccess} reset={resetAll} />}
    </>
  );
}

const mapState = (state) => ({
  data: state.bookings.api.response.data,
  sheets: state.sheet.response.data,
  loading: state.bookings.api.loading,
  error: state.bookings.api.error,
  todayBookingState: state.approvedOverTotal.response.data,
  sheetError: state?.imoprtBookingReducer?.error?.message,
  sheetSuccess: state?.imoprtBookingReducer?.response?.data?.message
});

const mapDispatch = {
  getBookings: ThunksBooking.BookingsListing,
  GetAssignedPaginated: ThunksBooking.GetAssignedPaginated,
  GetDriverEventsBybooking: ThunksBooking.GetDriverEventsBybooking,
  GetBookingdriver: ThunksBooking.GetBookingdriver,
  AuthURL: ThunkSpreadSheet.AuthURL,
  CreateSheet: ThunkSpreadSheet.CreateSheet,
  getDisplayNameByUserId: ThunksUsers.getDisplayNameByUserId,
  getDisplayNameByClientId: ThunksCustomers.getDisplayNameByClientId,
  cancelPendingBooking: ThunksBooking.CancelPendingBooking,
  getTotalAndAssignedRides: ThunksBooking.getTotalAndAssignedRides,
  resetAll: Local.ImportSheet.api.reset
};

export default connect(mapState, mapDispatch)(BookingList);
