import moment from "moment";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  ListItem,
  Typography,
} from "@material-ui/core";
import DriverEventsProcess from "./DriverEventsProcess";
import {
  BookingMenuIcon,
  CarTypeIcon,
  LuggageIcon,
  PassengerUsersIcon,
  PhoneCallIcon,
  UserVerifiedIcon,
} from "../../../assets/icons";
import FlightCardDetail from "./FlightCardDetail";
import {
  ArrowDropDownCircleOutlined,
  EditOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUpOutlined,
  LocationOnOutlined,
  MoreVert,
} from "@material-ui/icons";
import UserAvatar from "../UserAvatar";
import TripTracking from "./TripTracking";
import * as momentTimeZone from "moment-timezone";
import DropDown from "../DropDown";
import {
  ContentCopyOutlined,
  EditLocationAltOutlined,
} from "@mui/icons-material";
import Image from "react-image-enlarger";

const useStyles = makeStyles((theme) => ({
  bookingCardSection: {
    width: "100%",
    padding: "10px 14px 10px 18px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "600",
  },
  subTitle: {
    fontSize: "12px",
    fontWeight: "500",
  },
  pickUpTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#BDBDBD",
  },

  footerBoxDetailSection: {
    display: "flex",
    padding: "6px 8px 6px 4px",
    border: "1px solid #00000014",
    borderRadius: "4px",
    alignItems: "center",
    marginRight: "11px",
  },
  expendedBoxBookingSection: {
    backgroundColor: "#28B0E10D",
    padding: "15px 11px 15px 54px",
    // width: "100%",
    // flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  expendedBoxDriverSection: {
    backgroundColor: "#28B0E10D",
    marginTop: "6px",
    padding: "15px  28px 15px 38px",
    // flex: 1,
    display: "flex",
  },
  endSectionTitle: {
    fontSize: "12px",
    fontWeight: "600",
  },
  endSectionExpandTitle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#BDBDBD",
  },
  endSectionExpandSubTitle: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#000000",
  },
}));

const driverEventDisplay = [
  { name: "Driver Departed", title: "DRIVER_DEPARTED_TO_PICKUP" },
  { name: "Driver Arrived", title: "DRIVER_ARRIVED_AT_PICKUP" },
  { name: "Passenger In Car", title: "DRIVER_DEPARTED_TO_DROPOFF" },
  { name: "Passenger Dropped Off", title: "DRIVER_ARRIVED_AT_DROPOFF" },
  { name: "No Show", title: "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW" },
];

const BookingCard = (props) => {
  const classes = useStyles();
  const {
    data,
    type,
    bookingDate,
    key,
    expand,
    toggleHandleEvent,
    driver,
    events,
    setSingleBooking,
    handleEditModal,
    handleDuplicateModal,
    anchorEl,
    setAnchorEl,
    handleCancelModal,
  } = props;
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <>
      <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} key={key}>
        <Card className={classes.bookingCardSection}>
          <Box>
            {/* upper section */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* date */}
              <Typography className={classes.title}>{bookingDate}</Typography>
              {/* Driver Events */}
              {type !== "planning" && data?.driver_events && (
                <>
                  <Box sx={{ display: "flex", gridColumnGap: "4px" }}>
                    {/* first look if there is no Show */}
                    {data?.driver_events?.filter(
                      (ef) =>
                        ef.eventName === "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW"
                    ).length > 0 ? (
                      <>
                        {driverEventDisplay?.map((driverEvent) => {
                          return (
                            <>
                              {data?.driver_events
                                ?.filter(
                                  (ef) => ef.eventName === driverEvent.title
                                )
                                .map((em) => {
                                  return (
                                    <>
                                      <DriverEventsProcess
                                        title={driverEvent.name}
                                        status={
                                          em.eventName ===
                                          "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW"
                                            ? "CANCEL"
                                            : "SUCCESS"
                                        }
                                      />
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {driverEventDisplay?.map((driverEvent) => {
                          return (
                            <>
                              {driverEvent.title !==
                                "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW" && (
                                <DriverEventsProcess
                                  title={driverEvent.name}
                                  status={
                                    data?.driver_events.length > 0
                                      ? data?.driver_events.findIndex(
                                          (e) =>
                                            e.eventName === driverEvent.title
                                        ) >= 0
                                        ? "SUCCESS"
                                        : "PENDING"
                                      : "PENDING"
                                  }
                                />
                              )}
                            </>
                          );
                        })}
                      </>
                    )}

                    {/* {driverEventDisplay?.map((driverEvent) => {
                      return (
                        <>
                          {driverEvent.title !==
                            "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW" && (
                            <DriverEventsProcess
                              title={driverEvent.name}
                              status={
                                data?.driver_events.length > 0
                                  ? data?.driver_events.findIndex(
                                      (e) => e.eventName === driverEvent.title
                                    ) >= 0
                                    ? "SUCCESS"
                                    : "PENDING"
                                  : "PENDING"
                              }
                            />
                          )}
                        </>
                      );
                    })} */}
                  </Box>
                </>
              )}

              {/* Booking NL Number */}
              <Typography className={classes.title}>{data.refId}</Typography>
            </Box>
            {
              <>
                {/* middle section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "19px",
                  }}
                >
                  {/* PickUp Detail */}
                  <Box sx={{ display: "flex", flex: 1 }}>
                    <Box>
                      <Typography className={classes.pickUpTitle}>
                        Pickup Time:
                      </Typography>
                      <Typography className={classes.title}>
                        {moment(data?.flightTime || data?.dateTime).format(
                          "HH:mm"
                        )}
                      </Typography>
                    </Box>
                    <Box sx={{ marginLeft: "42px", marginTop: "12px" }}>
                      <UserVerifiedIcon />
                      <Typography className={classes.subTitle}>
                        {data?.guestName}
                      </Typography>
                    </Box>
                  </Box>
                  {/* flight card */}
                  <Box sx={{ flex: 2 }}>
                    <FlightCardDetail data={data} />
                  </Box>
                  {/* Menu */}
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {type !== "history" && (
                      <DropDown
                        dropDownOpen={anchorEl}
                        setDropDownOpen={setAnchorEl}
                        uniqueId={data.id}
                        menu={[
                          {
                            onClick: () => {
                              setSingleBooking(data);
                              handleEditModal(true);
                            },
                            IconRender: () => <EditOutlined />,
                            title: "Edit Details",
                          },
                          {
                            onClick: () => {
                              setSingleBooking(data);
                              handleDuplicateModal(true);
                            },
                            IconRender: () => <ContentCopyOutlined />,
                            title: "Duplicate",
                          },
                        ]}
                      />
                    )}
                  </Box>
                </Box>
                {/* end section */}
                <Box>
                  {/* More Detail */}
                  <Box className="booking-card-more-section">
                    <Accordion
                      expanded={data?.id == expand ? true : false}
                      style={{ boxShadow: "none" }}
                    >
                      <AccordionSummary
                        style={{ padding: "0",cursor:"default" }}
                        IconButtonProps={{
                          onClick: () => {
                            toggleHandleEvent();
                          },
                        }}
                        expandIcon={
                          data.id == expand ? (
                            <Box style={{display:"flex",alignItems:"center"}}>
                            <Typography
                              className={classes.endSectionTitle}
                              style={{ color: "#29AEE6" }}
                            >
                              More Details
                             
                            </Typography>
                            <KeyboardArrowDownOutlined
                              style={{ color: "#29AEE6", padding: "0" }}
                            />
                          </Box>
                            
                          ) : (
                            <Box style={{display:"flex",alignItems:"center"}}>
                            <Typography
                              className={classes.endSectionTitle}
                              style={{ color: "#29AEE6" }}
                            >
                              More Details
                            
                            </Typography>
                            <KeyboardArrowRightOutlined
                              style={{ color: "#29AEE6", padding: "0" }}
                            />
                          </Box>
                            
                          )
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {type === "history" ? (
                              <>
                                {data?.driver_events?.findIndex(
                                  (ef) =>
                                    ef.eventName ===
                                    "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW"
                                ) >= 0 ? (
                                  <>
                                    <Typography
                                      style={{
                                        marginRight: "42px",
                                        color: "#F42A41",
                                        fontSize: "12px",
                                        fontWeight: "600px",
                                      }}
                                    >
                                      No Show
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      style={{
                                        marginRight: "42px",
                                        color: "#54A753",
                                        fontSize: "12px",
                                        fontWeight: "600px",
                                      }}
                                    >
                                      Trip Completed
                                    </Typography>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Typography
                                  style={{
                                    marginRight: "42px",
                                    color: "#F42A41",
                                    fontSize: "12px",
                                    fontWeight: "600px",
                                    cursor:"pointer"
                                  }}
                                  onClick={() => {
                                    setSingleBooking(data);
                                    handleCancelModal();
                                  }}
                                >
                                  Cancel Booking
                                </Typography>
                              </>
                            )}

                            <Box className={classes.footerBoxDetailSection}>
                              <PassengerUsersIcon />
                              <Typography
                                className={classes.endSectionTitle}
                                style={{ marginLeft: "10px" }}
                              >
                                {data?.noOfPeople}
                              </Typography>
                            </Box>
                            <Box className={classes.footerBoxDetailSection}>
                              <LuggageIcon />
                              <Typography
                                className={classes.endSectionTitle}
                                style={{ marginLeft: "12px" }}
                              >
                                {data?.noOfLuggage}
                              </Typography>
                            </Box>

                            <Box className={classes.footerBoxDetailSection}>
                              <CarTypeIcon />
                              <Typography
                                className={classes.endSectionTitle}
                                style={{ marginLeft: "10px" }}
                              >
                                {/* standard */}
                                {data?.carType}
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Box>
                            <Typography
                              className={classes.endSectionTitle}
                              style={{ color: "#29AEE6" }}
                            >
                              More Details
                            </Typography>
                          </Box> */}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: "0" }}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            borderTop: "1px solid #00000036",
                            paddingTop: "16px",
                          }}
                        >
                          {/* Driver Dispatcher detail */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Box className={classes.expendedBoxBookingSection}>
                              <Box>
                                <Typography
                                  className={classes.endSectionExpandTitle}
                                >
                                  Booking Requested
                                </Typography>
                                <Typography
                                  className={classes.endSectionExpandSubTitle}
                                >
                                  {data?.fromWebhook ? "Booking.com" : "User"}
                                </Typography>
                              </Box>
                              {data?.notes && (
                                <Box>
                                  <Typography
                                    className={classes.endSectionExpandTitle}
                                  >
                                    Note For Pickup
                                  </Typography>
                                  <Typography
                                    className={classes.endSectionExpandSubTitle}
                                  >
                                    {data?.notes}
                                  </Typography>
                                </Box>
                              )}

                              <Box>
                                <Typography
                                  className={classes.endSectionExpandTitle}
                                >
                                  Passenger Local Time
                                </Typography>
                                <Typography
                                  className={classes.endSectionExpandSubTitle}
                                >
                                  {momentTimeZone(data?.dateTime)
                                    .tz(data?.timeZone)
                                    .format("HH:mm")}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className={classes.endSectionExpandTitle}
                                >
                                  Time Left to Pickup
                                </Typography>
                                <Typography
                                  className={classes.endSectionExpandSubTitle}
                                >
                                  {moment.utc(data?.dateTime).fromNow()}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  className={classes.endSectionExpandTitle}
                                >
                                  Payment Method
                                </Typography>
                                <Typography
                                  className={classes.endSectionExpandSubTitle}
                                >
                                  {data?.paymentType}
                                </Typography>
                              </Box>
                            </Box>
                            {type === "planning" ? (
                              <>
                                <Box
                                  className={classes.expendedBoxDriverSection}
                                  style={{ justifyContent: "center" }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "16px",
                                    }}
                                  >
                                    No Driver Assigned
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                {data?.driver && (
                                  <>
                                    <Box
                                      className={
                                        classes.expendedBoxDriverSection
                                      }
                                      sx={{ justifyContent: "space-between" }}
                                    >
                                      <Box>
                                        <UserAvatar
                                          size="md"
                                          user={{
                                            fullName: data?.driver.fullName,
                                            role: "Driver Name",
                                            imageUrl:
                                              data?.driver?.imageUrl &&
                                              data?.driver?.imageUrl,
                                          }}
                                        />
                                      </Box>

                                      {data?.driver.phoneNumber && (
                                        <Box sx={{ display: "flex" }}>
                                          <PhoneCallIcon
                                            style={{ marginTop: "4px" }}
                                          />
                                          <Box sx={{ marginLeft: "10px" }}>
                                            <Typography
                                              className={
                                                classes.endSectionExpandTitle
                                              }
                                            >
                                              Driver Number
                                            </Typography>
                                            <Typography
                                              className={classes.title}
                                            >
                                              {data?.driver.phoneNumber}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}

                                      {driver && (
                                        <>
                                          <Box>
                                            <Typography
                                              className={
                                                classes.endSectionExpandTitle
                                              }
                                            >
                                              Vehicle Model
                                            </Typography>
                                            <Typography
                                              className={classes.title}
                                            >
                                              {driver?.brand.brandName}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography
                                              className={
                                                classes.endSectionExpandTitle
                                              }
                                            >
                                              Vehicle Plate No.
                                            </Typography>
                                            <Typography
                                              className={classes.title}
                                            >
                                              {driver?.numberPlate}
                                            </Typography>
                                          </Box>
                                          <Box>
                                            <Typography
                                              className={
                                                classes.endSectionExpandTitle
                                              }
                                            >
                                              Track Driver
                                            </Typography>
                                            <Box sx={{ display: "flex" }}>
                                              <LocationOnOutlined
                                                style={{
                                                  color: "#29AEE6",
                                                  height: "17px",
                                                  width: "15px",
                                                }}
                                              />
                                              <Typography
                                                style={{
                                                  color: "#29AEE6",
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                Track
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </>
                                      )}
                                    </Box>
                                  </>
                                )}
                                {data?.dispatcher && (
                                  <>
                                    <Box
                                      className={
                                        classes.expendedBoxDriverSection
                                      }
                                    >
                                      <Box>
                                        <Box>
                                          <UserAvatar
                                            size="md"
                                            user={{
                                              fullName:
                                                data?.dispatcher.fullName,
                                              role: "Dispatcher Name",
                                              imageUrl:
                                                data?.dispatcher?.imageUrl &&
                                                data?.driver?.imageUrl,
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                      {data?.dispatcher.phoneNumber && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            marginLeft: "30px",
                                          }}
                                        >
                                          <PhoneCallIcon
                                            style={{ marginTop: "4px" }}
                                          />
                                          <Box sx={{ marginLeft: "10px" }}>
                                            <Typography
                                              className={
                                                classes.endSectionExpandTitle
                                              }
                                            >
                                              Driver Number
                                            </Typography>
                                            <Typography
                                              className={classes.title}
                                            >
                                              (505) 555-0125
                                            </Typography>
                                          </Box>
                                        </Box>
                                      )}
                                    </Box>
                                  </>
                                )}
                                {/* No show report */}
                                {type === "history" &&
                                  data?.cancellationReason && (
                                    <>
                                      <Box
                                        className={
                                          classes.expendedBoxDriverSection
                                        }
                                      >
                                        <Box sx={{ marginLeft: "10px" }}>
                                          <Typography
                                            className={
                                              classes.endSectionExpandTitle
                                            }
                                          >
                                            No Show Reason
                                          </Typography>
                                          <Typography
                                            className={classes.title}
                                            style={{ color: "#F42A41" }}
                                          >
                                            {data?.cancellationReason?.reason ||
                                              "Null"}
                                          </Typography>
                                        </Box>
                                        {/* images */}
                                        {data?.cancellationReason?.picture?.map(
                                          (image) => {
                                            return (
                                              <>
                                                <div style={{ marginLeft: 16 }}>
                                                  <Image
                                                    style={{
                                                      width: "150px",
                                                      height: "150px",
                                                    }}
                                                    // zoomed={zoomed}
                                                    src={image}
                                                    // onClick={() => saveAs(src, 'image.jpg')}
                                                    // onRequestClose={() => setZoomed(false)}
                                                  />
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </Box>
                                    </>
                                  )}
                              </>
                            )}
                          </Box>
                          {/* trip tacking */}
                          {type === "history" && data?.driver_events && (
                            <Box>
                              <TripTracking events={data?.driver_events} />
                            </Box>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </>
            }
          </Box>
        </Card>
      </ListItem>
    </>
  );
};

export default BookingCard;
