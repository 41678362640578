import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Pressable from "../../../components/commons/pressable";
import { Google, Apple, Facebook } from "../../../assets/icons";
import LoginRepo from "../../../redux/actions/local/login";
import { LocalLoginRepo, Field } from "../../../redux/actions/local/login";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MobioTextField from "../../../components/commons/fields/mobioTextField";
import ThunksLogin, {
  ThunksLoginRepo,
} from "../../../redux/actions/thunks/login";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";

interface LoginProps {
  displayName: string;
  loading: boolean;
  password: string;
  login: ThunksLoginRepo["postLogin"];
  updateField: LocalLoginRepo["field"]["update"];
  error?: any;
  reset: () => any;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "1rem",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7vh",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.disabled,
    borderBottomStyle: "solid",
    width: "45%",
  },
  or: {
    color: theme.palette.text.disabled,
  },
  forgotPassword: {
    color: theme.palette.primary.dark,
    marginTop: "2vh",
    alignSelf: "end",
  },
  signInContainer: {
    alignSelf: "center",
    marginTop: "7vh",
    color: theme.palette.text.disabled,
  },
  signIn: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
  container: {},
  customerLogin: {
    marginTop: "2vh",
    padding: theme.spacing(1.5),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: 15,
    alignSelf: "center",
  },
  privacyPolicy: {
    alignSelf: "center",
    marginTop: "2vh",
    color: theme.palette.primary.dark,
  },
}));
const Login: React.FunctionComponent<LoginProps> = ({
  displayName,
  loading,
  password,
  login,
  updateField,
  reset,
  error,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleChange = (field: Field, value: string) =>
    updateField(field, value);
  useEffect(() => {
    if (error.status) {
      // alert(error.message);
    }
  }, [error]);
  return (
    <>
      <div className={classes.container} />
      <MobioTextField
        fullWidth
        label="Email/Username"
        onChange={(event) => handleChange("displayName", event.target.value)}
        value={displayName}
        className={classes.field}
      />
      <div className={classes.field}>
        <FormControl fullWidth>
          <InputLabel htmlFor="password-first">Password</InputLabel>
          <Input
            id="password-first"
            fullWidth
            onChange={(event) => handleChange("password", event.target.value)}
            type={showPassword ? "text" : "password"}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className={classes.forgotPassword}>
        <Link to="/forgot-password/enterprise" className={classes.signIn}>
          Forgot Password?
        </Link>
      </div>
      <Pressable
        onClick={() => {
          login();
        }}
        size={"large"}
        title={"Sign In"}
        loading={loading}
      />
      <div className={classes.divider}>
        <div className={classes.border} />
        <span className={classes.or}>OR</span>
        <div className={classes.border} />
      </div>
      <Link
        to="/customer"
        className={`${classes.customerLogin} ${classes.signIn}`}
      >
        Customer Login
      </Link>
      {/* https://www.mobiodispatch.com/privacyPolicy */}
      {/* <div style={{ alignSelf: "center" }}>
        <IconButton>
          <Google />
        </IconButton>
        <IconButton>
          <Facebook />
        </IconButton>
        <IconButton>
          <Apple />
        </IconButton>
      </div> */}
      <span className={classes.signInContainer}>
        Not a member yet?{" "}
        <Link to="/sign-up" className={classes.signIn}>
          Register here.
        </Link>

      </span>
      <span className={classes.privacyPolicy}>
        <a href="https://www.mobiodispatch.com/privacyPolicy " target="_blank">
          See Privacy Policy{" "}
        </a>
      </span>
      <div className={classes.container} />
      {error.status && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  displayName: state.login.state.displayName,
  password: state.login.state.password,
  loading: state.login.api.loading,
  error: state.login.api.error,
});

const mapDispatch = {
  updateField: LoginRepo.field.update,
  login: ThunksLogin.postLogin,
  reset: Local.Login.api.reset,
};

export default connect(mapState, mapDispatch)(Login);
