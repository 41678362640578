import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CreatebookingModal from "./CreateBookingModal";
import Bookings from "../../../../redux/actions/local/bookings";
import { connect } from "react-redux";
import "./index.css"
import MOButton from "../../../../components/commons/MOButton";
import ImportModal from "../ImportModal";
import { Box } from "@material-ui/core";

function SimpleDialogDemo({ role, type, updateField }) {
  const [open, setOpen] = React.useState(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCustomerOpen = () => {
    setOpenCustomer(true)
  }
  const handleCustomerClose = () => {
    setOpenCustomer(false)
  }
  return (
    <div>
      <Box style={{ display: "flex" }}>
        <Box style={{ marginRight: '10px' }}>
          <MOButton onClick={handleClickOpen} title="Add Booking" />
        </Box>
        {role == 'Organization' &&
          <MOButton onClick={handleCustomerOpen} title="Import Bookings" />
        }
        <ImportModal isOpen={openCustomer} onPressClose={handleCustomerClose} />
        <CreatebookingModal open={open} onClose={handleClose} role={role} />
      </Box>
    </div>
  );
}


export default connect(null, null)(SimpleDialogDemo);
