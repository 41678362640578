import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import colors from "../../theme/colors";

export default function ItemList({ list = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>{list[0]?.name}</p>
      {list?.length > 1 && (
        <>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleMenuOpen}
            sx={{
              borderRadius: "50%",
              minWidth: "30px",
              width: "30px",
              height: "30px",
              marginLeft: "8px",
              borderColor: colors.text.dark,
              color: colors.text.dark,
            }}
          >
            +{list?.length - 1}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {list?.slice(1).map((data, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleMenuClose();
                }}
              >
                {data?.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
}
