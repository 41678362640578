import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as XLSX from 'xlsx';
import ThunksSpreadSheet from '../../../redux/actions/thunks/spread-sheet';
import moment from 'moment';
import ThunksBooking from '../../../redux/actions/thunks/bookings';
import ThunksCustomers from '../../../redux/actions/thunks/customer';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  formatText: {
    fontSize: 14,
    maxWidth: 260
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: "24%",
    },
  },
};

function getStyles(item, selected, Theme) {
  return {
    fontWeight:
      item.id !== selected
        ? Theme.typography.fontWeightRegular
        : Theme.typography.fontWeightMedium,
  };
}

const ImportModal = ({ isOpen, onPressClose, allCustomers, ImportSheet, getBookings, getCustomers }) => {
  const [selected, setSelected] = useState(null);
  const [loader, setLoader] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [isEditable, setIsEditable] = useState(false); // State for toggling edit mode
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const classes = useStyles();
  const theme = useTheme();

  const handleClose = () => {
    onPressClose();
  };

  const handleChange = (event) => {
    const { target: { value } } = event;
    setSelected(value);
  };

  useEffect(() => {
    if (allCustomers?.length <= 0) {
      getCustomers();
    }
  }, []);
  useEffect(() => {
    if (previewOpen && isInitialLoad) {
     
      setSelectAllChecked(true);
      setCheckedRows(editableData.map((_, index) => index));
      setIsInitialLoad(false); 
    }
  }, [previewOpen, editableData, isInitialLoad]);
  
  
  useEffect(() => {
    if (!previewOpen) {
      setIsInitialLoad(true);
    }
  }, [previewOpen]);
  

  const ImportFunction = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    let file = e.target.files[0];
    if (!(file instanceof Blob)) {
      return;
    }
    setLoader(true);
    let reader = new FileReader();
    reader.onload = function (e) {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: 'array' });
      let worksheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[worksheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      console.log('Raw JSON data:', jsonData); // Log raw data
  
      // Parse and format the dates in the data
      jsonData = jsonData.filter((item, index) => index !== 0 && item.length > 0)
        .map(row => {
          // Assuming the date is in column 1 and time is in column 2
          row[1] = moment(XLSX.SSF.parse_date_code(row[1])).format('MM/DD/YYYY');
          console.log('Formatted Date:', row[1]); // Log formatted date
          return row;
        });
  
      console.log('Processed JSON data:', jsonData); // Log processed data
  
      setExcelData(jsonData);
      setEditableData(jsonData);
      setCheckedRows(jsonData.map((_, index) => index)); // Check all rows by default
      setPreviewOpen(true);
      setLoader(false);
    };
    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };
  

  const handleCellChange = (e, rowIndex, cellIndex) => {
    const newValue = e.target.value;
    setEditableData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex][cellIndex] = newValue;
      return updatedData;
    });
  };

  

  const handleCheckboxChange = (rowIndex) => {
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = [...prevCheckedRows];
      if (newCheckedRows.includes(rowIndex)) {
        const updatedCheckedRows = newCheckedRows.filter((index) => index !== rowIndex);
        setSelectAllChecked(false);
        return updatedCheckedRows;
      } else {
        const updatedCheckedRows = [...newCheckedRows, rowIndex];
        if (updatedCheckedRows.length === editableData.length) {
          setSelectAllChecked(true);
        }
        return updatedCheckedRows;
      }
    });
  };
  
  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows(editableData.map((_, index) => index));
    }
    setSelectAllChecked(!selectAllChecked);
  };
  

  const handleSubmit = async () => {
    setLoader(true);
    setIsEditable(false); // Disable edit mode
    setSelectAllChecked(false); // Uncheck "Select All"
    
    const finalData = {
      data: await transformExcelData(editableData.filter((_, index) => checkedRows.includes(index))),
    };
  
    try {
      await ImportSheet(finalData);
      handleClose();
      await getBookings("planning", "", "", "");
    } catch (error) {
      console.error("Error submitting the data:", error);
    } finally {
      setLoader(false);
      setSelected(null);
      setPreviewOpen(false);
      setExcelData([]);
      setEditableData([]);
      setCheckedRows([]);
    }
  };
  

  const transformExcelData = async (data) => {
    const epoch = moment().unix();
    const genId = `EX${epoch}`;
  
    return Promise.all(data.map(async (item) => {
      if (!item[1] || !item[2] || !item[6] || !item[7] || !item[8]) {
        return;
      }
  
      // Combine date and time columns (item[1] for date, item[2] for time)
      const combinedDateTime = `${item[1]} ${item[2]}`;
      console.log('Combined DateTime:', combinedDateTime); // Log combined datetime
  
      const excelDate = convertToExcelDate(combinedDateTime);
      console.log('Excel Date:', excelDate); // Log converted Excel date
  
      return {
        bookingReference: item[0],
        dateTime: excelDate,  // Use the converted Excel serial date number
        noOfPeople: item[6].toString(),
        carType: item[7],
        notes: item[10],
        paymentType: item[11],
        pickup: item[8].trim(),
        destination: item[9].trim(),
        guestPhoneNumber: item[5],
        guestName: item[4],
        flightNumber: item[3],
        refId: genId,
        clientId: selected
      };
    }));
  };
  
  


  function generateExcel() {
    const data = [
      ['BOOKING REFERENCE', 'PICKUP DATE', 'PICKUP TIME', 'FLIGHT NUMBER', "PASSENGER NAME", "CUSTOMER CONTACT NUMBER", "NUMBER OF PASSENGERS", "VEHICLE TYPE", "PICKUP ADDRESS", "DROP OFF ADDRESS", "PASSENGER COMMENTS", "PAYMENT TYPE"],
      ['xxxxxxxxxxx', "1/11/2024", "23:24:00", 'XXXXXX', "Passenger Name", "XXXXXXXXXX", "no of passengers (1,2,3 etc)", "vehicle Type (e.g STANDARD)", "Address 1", "Address 2", "Passenger comment if have", "payment type (ie. CASH,CARD,ACCOUNT)"],
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'booking_format_example.xlsx');
}


  return (
    <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    disableBackdropClick={loader} // Disable backdrop click while loading
    disableEscapeKeyDown={loader} // Disable escape key while loading
  >
    {loader && (
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        zIndex: 9999,
      }}>
        <CircularProgress />
      </Box>
    )}
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      Select Customer For Which You Want To Import The Bookings
    </DialogTitle>
    <DialogContent dividers>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Customers</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          displayEmpty={true}
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          MenuProps={MenuProps}
          disabled={loader} // Disable select while loading
        >
          {allCustomers.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(item, selected, theme)}
            >
              {item.companyName || `${item.firstName} ${item.lastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <input
        type="file"
        accept=".xlsx,.xls"
        onChange={ImportFunction}
        id="fileUpload"
        style={{ display: 'none' }}
        disabled={loader} // Disable file input while loading
      />
      <Typography className={classes.formatText}>Just click <strong>'Download Format'</strong> for the right format needed to import your bookings.</Typography>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          cursor: "pointer"
        }}
        onClick={generateExcel}
        variant="contained"
        disabled={loader} // Disable button while loading
      >
        Download Format
      </Button>
  
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          cursor: "pointer"
        }}
        disabled={!selected || loader} // Disable button while loading
        onClick={() => document.getElementById('fileUpload').click()}
        variant="contained"
      >
        Select File
      </Button>
    </DialogActions>
  
    {/* Preview Dialog */}
    <Dialog
      onClose={() => setPreviewOpen(false)}
      open={previewOpen}
      maxWidth="xl" // Increased modal size
      fullWidth
      disableBackdropClick={loader} // Disable backdrop click while loading
      disableEscapeKeyDown={loader} // Disable escape key while loading
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Preview Excel Data</Typography>
          <Button
            style={{ marginLeft: 'auto' }}
            onClick={() => setIsEditable(!isEditable)}
            variant="contained"
            color={isEditable ? "secondary" : "primary"}
            disabled={loader} // Disable button while loading
          >
            {isEditable ? "Disable Edit" : "Enable Edit"}
          </Button>
        </div>
      </DialogTitle>
  
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAllChecked}
                  onChange={handleSelectAllChange}
                  disabled={loader} // Disable checkbox while loading
                />
              </TableCell>
              {[
                'BOOKING REFERENCE', 
                'PICKUP DATE ', 
                'PICKUP TIME',
                'FLIGHT NUMBER', 
                'PASSENGER NAME', 
                'CUSTOMER CONTACT NUMBER', 
                'NUMBER OF PASSENGERS', 
                'VEHICLE TYPE', 
                'PICKUP ADDRESS', 
                'DROP OFF ADDRESS', 
                'PASSENGER COMMENTS', 
                'PAYMENT TYPE'
              ].map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {editableData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>
                  <Checkbox
                    checked={checkedRows.includes(rowIndex)}
                    onChange={() => handleCheckboxChange(rowIndex)}
                    disabled={loader} // Disable checkbox while loading
                  />
                </TableCell>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    <TextField
                      value={cell}
                      onChange={(e) => handleCellChange(e, rowIndex, cellIndex)}
                      fullWidth
                      disabled={!isEditable || loader} // Disable editing and input while loading
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPreviewOpen(false)} variant="contained" color="secondary" disabled={loader}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loader || checkedRows.length === 0}>
  Submit
</Button>

      </DialogActions>
    </Dialog>
  </Dialog>
  
  );
};
function convertToExcelDate(dateStr) {
  console.log('Original Date String:', dateStr); // Log original date string

  // Parse the date string to a local Date object
  const localDate = new Date(dateStr);

  // Create a UTC Date object by extracting the year, month, day, hour, minute, and second from the local Date object
  const utcDate = new Date(Date.UTC(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    localDate.getHours(),
    localDate.getMinutes(),
    localDate.getSeconds()
  ));

  console.log('UTC Date:', utcDate); // Log UTC date object

  // Define the base date in Excel's date system (January 1, 1900)
  const excelBaseDate = new Date(Date.UTC(1900, 0, 1));

  // Calculate the number of days between the base date and the UTC date
  const days = Math.floor((utcDate - excelBaseDate) / (1000 * 60 * 60 * 24));

  // Calculate the fraction of the day
  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();
  const fractionOfDay = (hours / 24) + (minutes / 1440) + (seconds / 86400);

  // Return the serial date number
  const excelDate = days + 2 + fractionOfDay; // Adding 2 accounts for Excel's incorrect leap year handling
  console.log('Final Excel Serial Date:', excelDate); // Log final Excel serial date

  return excelDate;
}



const mapState = (state) => ({
  allCustomers: state?.customers?.response?.data || [],
});

const mapDispatch = {
  ImportSheet: ThunksSpreadSheet.ImportSheet,
  getBookings: ThunksBooking.BookingsListing,
  getCustomers: ThunksCustomers.Customers,
};

export default connect(mapState, mapDispatch)(ImportModal);