import React, { useEffect, useRef, useState } from "react";
import { BookingMenuIcon } from "../../assets/icons";

const DropDown = ({
  IconRender,
  menu = [],
  uniqueId = "",
  dropDownOpen = false,
  setDropDownOpen = () => {},
}) => {
  //   const {
  //     IconRender,
  //     menu = [],
  //     uniqueId = "",
  //     dropDownOpen = false,
  //     setDropDownOpen = () => {},
  //   } = props;
  //   menu will look like this pattern menu :[{onClick:()=>{},IconRender,title}]
  const [anchorEl, setAnchorEl] = useState(null);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDownOpen(null);
      }
    };

    if (dropDownOpen === uniqueId) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropDownOpen, uniqueId, setDropDownOpen]);

  
  return (
    <div ref={dropdownRef} className="dropdown-menu-section">
      <button  onClick={() => {
        if(dropDownOpen === uniqueId){
          setDropDownOpen(null)
        }else{
          setDropDownOpen(uniqueId)
        }
        // setIsDropDownOpen(true)
        }}>
        {/* <BookingMenuIcon /> */}
        {typeof IconRender === "function" ? IconRender() : <BookingMenuIcon />}
      </button>
      <div
        className={`${
          uniqueId === dropDownOpen
            ? "dropdown-menu-active"
            : "dropdown-menu-hide"
        } dropdown-menu-list`}
      >
        {menu?.map((item) => {
          return (
            <>
              <button
                onClick={() => {
                  item?.onClick();
                  setDropDownOpen("");
                }}
              >
                {typeof item.IconRender === "function" && item.IconRender()}{" "}
                {item.title}
              </button>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default DropDown;
