import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { connect } from "react-redux";
import ThunksNetworks from "../../redux/actions/thunks/networks";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selected, theme) {
  console.log("here is selected", { selected, name });
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function EditMultipleValue({ props, response, getNetworkForOrganization }) {
  const [networks, setNetworks] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getvalues(props.rowData);
  }, []);

  const getvalues = async (value) => {
    let net = [];
    for (let k in response) {
      net.push(response[k].name);
    }
    setNetworks(net);
    // get those networks which already assigned to this role
    let nameArray = await getNetworkForOrganization(value.id);
    setSelected(nameArray);
  };

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log("here is se", value);
    setSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("response", response);
    let temp = [];
    for (let i in value) {
      for (let k in response) {
        if (response[k].name === value[i]) {
          temp.push(response[k].id);
        }
      }
    }

    props.onChange(temp);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Name</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput label="Network Name" />}
          MenuProps={MenuProps}
        >
          {networks &&
            networks.length > 0 &&
            networks.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, selected, theme)}
              >
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

const mapState = (state) => ({
  response: state.networks.response.data,
});

const mapDispatch = {
  getNetworkForOrganization: ThunksNetworks.getNetworkForOrganization,
};

export default connect(mapState, mapDispatch)(EditMultipleValue);
